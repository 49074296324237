import * as Actions from '@actions/index';
import { AccountService, DashletService, ThresholdService } from '@services/index';
import { AppState, selectDataFromCommonEntity, selectEntity, selectProcessThresholdData } from '@reducers/index';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ContextMenu } from 'primeng/contextmenu';
import { ColumnTypes, CustomerLogo, DashletTrunkGroupList, MenuItem, TrunkGroup } from '@models/index';
import { FilterPipe } from 'src/app/pipes/dashlet-filter.pipe';
import { select, Store } from '@ngrx/store';
import { combineLatest, Subscription, take } from 'rxjs';
import { ThresholdItemComponent } from '../threshold-item/threshold-item.component';

@Component({
    selector: 'app-dashlet-trunk-group-list',
    templateUrl: './dashlet-trunk-group-list.component.html',
    styleUrls: ['./dashlet-trunk-group-list.component.scss'],
    providers: [FilterPipe]
})
export class DashletTrunkGroupListComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletTrunkGroupList;
    @ViewChild('thresholdMenu') thresholdMenu: ContextMenu;
    @ViewChild('thresholdItem') thresholdItem: ThresholdItemComponent;
    sortType: string;
    usageProportion: string = '';
    nameSearch: string = '';
    thresholdType: string = '';

    trunkContextMenuItems: MenuItem[] = [];

    public scrollHeight: number;

    public columns: ColumnTypes[] = [
        {
            columnDef: 'severity',
            header: '',
            cell: (row: TrunkGroup) => row.severity,
            type: 'severity'
        },
        {
            columnDef: 'name',
            header: 'Name',
            cell: (row: TrunkGroup) => `${row.name} - ${row.thresholds?.length}`,
            filterType: 'text',
            footerRowDef: () => 'Total'
        },
        {
            columnDef: 'number',
            header: 'Number',
            type: 'numeric'
        },
        {
            columnDef: 'active',
            header: 'In Use',
            cell: (row: TrunkGroup) => `${row.active} / ${row.size}`,
            footerRowDef: () => this.usageProportion
        }
    ];

    private subscription: Subscription = new Subscription();

    constructor(
        private dashletService: DashletService,
        private accountService: AccountService,
        private thresholdService: ThresholdService,
        private filterPipe: FilterPipe,
        private store$: Store<AppState>
    ) {
        this.sortType = 'number';
    }

    public ngOnInit(): void {
        switch (this.dashlet.getSize().id) {
            case 0:
                this.scrollHeight = 4;
                break;
            case 1:
                this.scrollHeight = 7;
                break;
            case 2:
                this.scrollHeight = 12;
                break;
        }

        this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));
        this.subscription.add(
            this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
                if (logo) {
                    this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                }
            })
        );

        // sub ipo trunks
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipmentId,
                command: this.dashlet.commandTypeIdIpoUtilization
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdIpoUtilization
            })
        );
        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(this.dashlet.equipmentId + this.dashlet.commandTypeIdIpoUtilization)
                    )
                )
                .subscribe(data => {
                    if (data) {
                        this.dashlet.processDataIpo(data);
                    }
                })
        );

        // sub acm trunks
        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipmentId,
                command: this.dashlet.commandTypeIdAcmTrunkGroups
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdAcmTrunkGroups
            })
        );

        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipmentId,
                command: this.dashlet.commandTypeIdAcmUtilization
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdAcmUtilization
            })
        );

        this.subscription.add(
            combineLatest([
                this.store$.pipe(
                    select(
                        selectDataFromCommonEntity(this.dashlet.equipmentId + this.dashlet.commandTypeIdAcmTrunkGroups)
                    )
                ),
                this.store$.pipe(
                    select(
                        selectDataFromCommonEntity(this.dashlet.equipmentId + this.dashlet.commandTypeIdAcmUtilization)
                    )
                )
            ]).subscribe(([groups, utilization]) => {
                if (groups || utilization) {
                    this.dashlet.processDataAcm(groups, utilization);
                }
            })
        );

        this.store$.dispatch(
            Actions.SubscribeToRealTimeService({
                equipmentId: this.dashlet.equipmentId,
                command: this.dashlet.commandTypeIdAepUtilization
            })
        );
        this.store$.dispatch(
            Actions.GetNotifyCommonEntitys({
                equipmentId: this.dashlet.equipmentId,
                commandTypeId: this.dashlet.commandTypeIdAepUtilization
            })
        );
        this.subscription.add(
            this.store$
                .pipe(
                    select(
                        selectDataFromCommonEntity(this.dashlet.equipmentId + this.dashlet.commandTypeIdAepUtilization)
                    )
                )
                .subscribe(data => {
                    if (data) {
                        this.dashlet.processDataAep(data);
                    }
                })
        );

        // threshold data
        this.store$.dispatch(Actions.GetProcessThresholdData({ equipmentId: this.dashlet.equipmentId }));
        this.subscription.add(
            this.store$.pipe(select(selectProcessThresholdData(this.dashlet.equipmentId))).subscribe(data => {
                if (data) {
                    this.dashlet.processThresholdData(data);
                }
            })
        );

        this.subscription.add(
            this.dashlet.trunksReceived.subscribe(() => {
                this.updateUsageProportion(this.nameSearch);
            })
        );

        this.subscription.add(this.accountService.hasCapacityFeature(this.dashlet.customer.customerId)
            .pipe(take(1))
            .subscribe(capacityEnabled => {
                this.trunkContextMenuItems.push(
                    {
                        label: 'Manage Thresholds',
                        title: !capacityEnabled ? this.getCapacityUnsubscribedText() : '',
                        disabled: !capacityEnabled,
                        command: () => {
                            this.thresholdService.openThresholdManagement(this.accountService.getUserDetails().EntityId);
                        }
                    },
                    {
                        label: 'Create Thresholds',
                        title: !capacityEnabled ? this.getCapacityUnsubscribedText() : '',
                        disabled: !capacityEnabled,
                        command: () => {
                            this.thresholdService.createThreshold(this.accountService.getUserDetails().EntityId);
                        }
                    }
                );
            }))
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    getLayout() {
        const s = this.dashlet.getSize();
        if (s !== null) {
            return s.id;
        }
        return -1;
    }

    getCapacityUnsubscribedText() {
        return this.dashletService.getCapacityUnsubscribedText();
    }
    private updateUsageProportion(value) {
        let trunks = this.dashlet.trunks.slice();

        let filter = {
            name: value
        };

        trunks = this.filterPipe.transform(this.dashlet.trunks, filter);

        let usage = 0;
        let total = 0;

        for (let trunk of trunks) {
            usage += trunk.active;
            total += trunk.size;
        }

        this.usageProportion = `${usage}/${total}`;
        this.dashlet.trunks = [...this.dashlet.trunks];
    }
}
