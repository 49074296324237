
    <dashlet-table 
    [columns]="columns"
    [dataSource]="dashlet.trunks"
    [pagination]="true"
    [pageLength]="scrollHeight-1"
    [tableHeightRows]="scrollHeight"
    [menuItems]="trunkContextMenuItems"
    [loading]="dashlet.loading"
    ></dashlet-table>
