import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
    AlarmExtended,
    AlarmAdditionalData,
    IPNREndpointsData,
    AlarmResponseLogData,
    Disk,
    AlarmSuppressionExtended
} from '@models/index';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { SuppressAlarmDto } from '@modules/dashboard/components/dashlet-alarms-summary/alarm-suppression-dialog/alarm-suppression-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class DashletDataProcessService {
    constructor(protected http: HttpClient) {}

    /**
     * Extract the total memory and memory usage from the data row
     * @param row
     */
    public processMemData(row: any) {
        var used = Number(row.MemoryUsedBuffersCache) || 0;
        var total = Number(row.MemoryTotal) || 0;
        if (total !== 0) {
            if (used === 0) {
                used = Number(row.MemoryUsed) || 0;
            }
            return {
                memoryUsagePercentage: ((used / total) * 100).toString()
            };
        }
    }

    /**
     * extract the disk usage proportion form the data row
     * @param row
     */
    public processDiskUsage(row: any) {
        let disks: Disk[] = [];
        for (let rowData of row) {
            let disk: Disk = {
                id: rowData.FileSystem + rowData.MountPoint || '',
                name: rowData.FileSystem,
                mountedOn: rowData.MountPoint,
                totalSpace: parseInt(rowData.Available) + parseInt(rowData.Used),
                totalUsed: rowData.Used,
                percentageUsed:
                    rowData.PercentUsed !== undefined ? rowData.PercentUsed / 100 : rowData.Used / rowData.Size1KBlocks
            };
            disks.push(disk);
        }
        return disks;
    }

    public processTotalDiskUsage(row: any): Disk {
        let total = 0;
        let used = 0;
        for (let rowData of row) {
            total += Number(rowData.Size1KBlocks);
            used += Number(rowData.Used);
        }
        if (total !== 0) {
            return {
                percentageUsed: Math.max(0, Math.min(1, used / total)),
                totalUsed: used,
                totalSpace: total,
                name: 'Total',
                mountedOn: ''
            };
        }
    }

    /**
     * Extract the cpu usage percentage from the data row
     * @param row
     */
    public processCpuData(row: any) {
        let cpuUsed = 0;
        cpuUsed += Number(row.UserPercent) || 0;
        cpuUsed += Number(row.SystemPercent) || 0;
        cpuUsed += Number(row.IOWaitPercent) || 0;
        return Math.max(0, Math.min(100, cpuUsed)).toString();
    }

    public processNetworkData(row: any) {
        let packetLoss = 0;
        const data = {
            averagePing: row.ReplyRoundTripAverageMs,
            maxPing: row.ReplyRoundTripMaximumMs,
            packetLoss: row.PacketLossPercentage
        };

        if (row.ReplyRoundTripAverageMs === '' || !row.ReplyRoundTripAverageMs) {
            data.averagePing = '---';
        }
        if (row.ReplyRoundTripMaximumMs === '' || !row.ReplyRoundTripMaximumMs) {
            data.maxPing = '---';
        }
        packetLoss += Number(+row.PacketLossPercentage) || 0;
        packetLoss = Math.max(0, Math.min(100, packetLoss));
        data.packetLoss = packetLoss.toString();
        return data;
    }

    public getUtilizationSeverity(util: number): number {
        if (!isNaN(util)) {
            if (util <= 0.5) {
                return 0;
            } else if (util <= 0.8) {
                return 1;
            } else {
                return 2;
            }
        }
        return -1;
    }

    public getAlarmsData(customerId: string, entityId: string) {
        return this.http.get<any[]>(environment.centralApi + `Alarm/${customerId}/Unresolved`);
    }

    public getAlarmExtended(alarmId: string, activated: Date, customerId: string): Observable<AlarmExtended> {
        return this.http.get<AlarmExtended>(environment.centralApi + `Alarm/${alarmId}/${customerId}`);
    }

    public getAlarmById(alarmId: string, customerId: string): Observable<AlarmSuppressionExtended> {
        return this.http.get<AlarmSuppressionExtended>(environment.centralApi + `Alarm/${alarmId}/${customerId}`);
    }

    public getAlarmRepeats(alarmId: string, activated: Date, customerId: string) {
        return this.http
            .get<Date[]>(environment.centralApi + `Alarm/${alarmId}/${customerId}/Repeats`)
            .pipe(map(r => r.map((d: any) => (d && new Date(d.activatedTime + 'Z')) || null)));
    }

    public getAlarmAdditionalData(alarmId: string, activated: Date, customerId: string) {
        return this.http.get<AlarmAdditionalData[]>(
            environment.centralApi + `Alarm/${alarmId}/${customerId}/AdditionalData`
        );
    }

    public getAlarmResponseLog(alarmId: string, customerId: string) {
        return this.http.get<AlarmResponseLogData[]>(
            environment.centralApi + `Alarm/${alarmId}/${customerId}/ResponseLogs`
        );
    }

    public resolveAlarm(alarmId: string, equipmentId: string, customerId: string): Observable<any> {
        return this.http.post(environment.centralApi + `Alarm/Resolve`, { alarmId, equipmentId, customerId });
    }

    public suppressAlarm(alarm: SuppressAlarmDto): Observable<object> {
        return this.http.post(environment.centralApi + `Alarm/Suppress`, alarm);
    }

    public getIPNREndpoints(customerId: string, equipmentId: string): Observable<IPNREndpointsData[]> {
        const rt = this.http.get<IPNREndpointsData[]>(environment.centralApi + `Ipnr/${customerId}/${equipmentId}`);
        return rt;
    }

    public addVQMFilterRuleSet(entityId: string, ruleSetName: string, rulesArrayFormat: string) {
        return this.http.post(environment.centralApi + 'VoiceQuality/Rule', {
            entityId,
            ruleSetName,
            rulesArrayFormat
        });
    }

    // "cip" stands for "calls in progress", which has now been changed to "streams in progress"

    public getCipStreamDetail(
        customerId: string,
        sourceIp: number,
        sourceEndPoint: string,
        sourceName: any,
        sourcePhoneName: any,
        remoteIp: any
    ) {
        return this.http.get<any[]>(
            `${environment.centralApi}VoiceQuality/${customerId}/${sourceIp}/${sourceEndPoint}/${
                sourceName ? sourceName : sourcePhoneName
            }/${remoteIp ? remoteIp : sourceIp}/StreamQualityInfo`
        );
    }

    public getServiceGatewayIdsAndName() {
        return this.http.get<any[]>(environment.centralApi + 'ServiceGateway/Info');
    }

    public dedupe(arr) {
        return arr.reduce(
            function (p, c) {
                var id = [c.x, c.y].join('|');
                if (p.temp.indexOf(id) === -1) {
                    p.out.push(c);
                    p.temp.push(id);
                }
                return p;
            },
            {
                temp: [],
                out: []
            }
        ).out;
    }
}
