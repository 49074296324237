/* eslint-disable no-undef */
/* eslint-disable no-empty */
import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { DashletRibbonSBCEdge } from '../../../../models/dashlets/DashletRibbonSBCEdge';

import { DashletSettingsService, DashletService } from '@services/index';

import { CustomerLogo } from '@models/index';
import { AppState, selectDataFromCommonEntity, selectEntity } from '@reducers/index';
import { Store, select } from '@ngrx/store';
import * as Actions from '@actions/index';
import { MenuItem } from 'primeng/api';
import { DashletSectionProcessorMemoryComponent } from '../dashlet-section-processor-memory/dashlet-section-processor-memory.component';

@Component({
    selector: 'app-dashlet-system-health-ribbon-sbc-edge',
    templateUrl: './dashlet-system-health-ribbon-sbc-edge.component.html',
    styleUrls: ['./dashlet-system-health-ribbon-sbc-edge.component.scss']
})
export class DashletRibbonSBCEdgeComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletRibbonSBCEdge;
    @ViewChild('processorMemory') processorMemory: DashletSectionProcessorMemoryComponent;

    public openSubcontainer: string = null;
    public openSubcontainerSeverity: number = 0;

    public occupancyContextMenuItems: MenuItem[];

    private subscription: Subscription = new Subscription();

    titles: string[] = ['Model:', 'Build Number:', 'SWe Lite ID:', 'Serial Number:'];

    constructor(
        private dashletSettingsService: DashletSettingsService,
        private dashletService: DashletService,
        private store$: Store<AppState>
    ) {}

    public ngOnInit(): void {
        this.dashletSettingsService.initSettings(this.dashlet.id);

        this.store$.dispatch(
            Actions.GetEntityLogo({
                entityId: this.dashlet.customer.customerId
            })
        );
        this.subscription.add(
            this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
                if (logo) {
                    this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                }
            })
        );
        if (this.dashlet.configured) {
            const id = this.dashlet.equipment.equipmentId;

            this.store$.dispatch(
                Actions.SubscribeToRealTimeService({
                    equipmentId: id,
                    command: this.dashlet.COMMAND_TYPE_ID_SIGNALLING_CONFIGURED
                })
            );
            this.store$.dispatch(
                Actions.GetNotifyCommonEntitys({
                    equipmentId: id,
                    commandTypeId: this.dashlet.COMMAND_TYPE_ID_SIGNALLING_CONFIGURED
                })
            );
            this.subscription.add(
                this.store$
                    .pipe(select(selectDataFromCommonEntity(id + this.dashlet.COMMAND_TYPE_ID_SIGNALLING_CONFIGURED)))
                    .subscribe(signallingConfigured => {
                        if (signallingConfigured && signallingConfigured.length && signallingConfigured[0].data[0]) {
                            this.store$.dispatch(
                                Actions.SubscribeToRealTimeService({
                                    equipmentId: id,
                                    command: this.dashlet.COMMAND_TYPE_ID_SIGNALLING_STATE
                                })
                            );
                            this.store$.dispatch(
                                Actions.GetNotifyCommonEntitys({
                                    equipmentId: id,
                                    commandTypeId: this.dashlet.COMMAND_TYPE_ID_SIGNALLING_STATE
                                })
                            );
                            this.subscription.add(
                                this.store$
                                    .pipe(
                                        select(
                                            selectDataFromCommonEntity(
                                                id + this.dashlet.COMMAND_TYPE_ID_SIGNALLING_STATE
                                            )
                                        )
                                    )
                                    .subscribe(signallingState => {
                                        if (signallingState && signallingState.length && signallingState[0].data[0]) {
                                            this.dashlet.processSignallingState(signallingState[0].data[0]);
                                        }
                                    })
                            );
                        }
                    })
            );

            this.store$.dispatch(
                Actions.SubscribeToRealTimeService({
                    equipmentId: id,
                    command: this.dashlet.COMMAND_TYPE_ID_LICENCE
                })
            );
            this.store$.dispatch(
                Actions.GetNotifyCommonEntitys({
                    equipmentId: id,
                    commandTypeId: this.dashlet.COMMAND_TYPE_ID_LICENCE
                })
            );
            this.subscription.add(
                this.store$
                    .pipe(select(selectDataFromCommonEntity(id + this.dashlet.COMMAND_TYPE_ID_LICENCE)))
                    .subscribe(data => {
                        if (data && data.length && data[0].data[0]) {
                            this.dashlet.processLicenceData(data[0].data[0]);
                        }
                    })
            );

            this.store$.dispatch(
                Actions.SubscribeToRealTimeService({
                    equipmentId: id,
                    command: this.dashlet.COMMAND_TYPE_ID_CALL_COUNTER
                })
            );
            this.store$.dispatch(
                Actions.GetNotifyCommonEntitys({
                    equipmentId: id,
                    commandTypeId: this.dashlet.COMMAND_TYPE_ID_CALL_COUNTER
                })
            );
            this.subscription.add(
                this.store$
                    .pipe(select(selectDataFromCommonEntity(id + this.dashlet.COMMAND_TYPE_ID_CALL_COUNTER)))
                    .subscribe(callData => {
                        if (callData && callData.length && callData[0].data[0]) {
                            this.dashlet.processCallCountersData(callData[0].data[0]);
                        }
                    })
            );

            this.store$.dispatch(
                Actions.SubscribeToRealTimeService({
                    equipmentId: id,
                    command: this.dashlet.COMMAND_TYPE_ID_DSP_INFO
                })
            );
            this.store$.dispatch(
                Actions.GetNotifyCommonEntitys({
                    equipmentId: id,
                    commandTypeId: this.dashlet.COMMAND_TYPE_ID_DSP_INFO
                })
            );

            this.store$.dispatch(
                Actions.GetCommonHistoric({
                    equipmentId: this.dashlet.equipment.equipmentId,
                    commandTypeId: this.dashlet.COMMAND_TYPE_ID_DSP_INFO,
                    from: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
                    to: new Date().toISOString(),
                    max: 3
                })
            );
            this.subscription.add(
                this.store$
                    .pipe(select(selectDataFromCommonEntity(id + this.dashlet.COMMAND_TYPE_ID_DSP_INFO)))
                    .subscribe(dsp => {
                        if (dsp && dsp.length && dsp[0].data[0]) {
                            this.dashlet.processDspInfo(dsp[0].data[0]);
                        }
                    })
            );
            this.store$.dispatch(
                Actions.SubscribeToRealTimeService({
                    equipmentId: id,
                    command: this.dashlet.COMMAND_TYPE_ID_NETWORK_INTERFACE_STATUS
                })
            );

            this.store$.dispatch(
                Actions.GetCommonHistoric({
                    equipmentId: this.dashlet.equipment.equipmentId,
                    commandTypeId: this.dashlet.COMMAND_TYPE_ID_NETWORK_INTERFACE_STATUS,
                    from: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
                    to: new Date().toISOString(),
                    max: 3
                })
            );
            this.store$.dispatch(
                Actions.GetNotifyCommonEntitys({
                    equipmentId: id,
                    commandTypeId: this.dashlet.COMMAND_TYPE_ID_NETWORK_INTERFACE_STATUS
                })
            );
            this.subscription.add(
                this.store$
                    .pipe(
                        select(selectDataFromCommonEntity(id + this.dashlet.COMMAND_TYPE_ID_NETWORK_INTERFACE_STATUS))
                    )
                    .subscribe(networkInterface => {
                        if (networkInterface && networkInterface.length && networkInterface[0].data[0]) {
                            this.dashlet.processNetworkInterfaceData(networkInterface[0].data[0]);
                        }
                    })
            );
        }
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public getSeverityColor(sev: number): string {
        return this.dashletService.getSeverityColor(sev);
    }

    public determineSeverity(data: any): string {
        if (data < 0.8) {
            return this.getSeverityColor(0);
        } else if (data < 0.9) {
            return this.getSeverityColor(1);
        } else {
            return this.getSeverityColor(2);
        }
    }

    public convertKBtoGB(data): string {
        let truncated = null;
        truncated = Number(data) / 1000000; // b to MB
        return truncated.toFixed(0).toString();
    }

    public convertTime(row): string {
        let result: string = '';
        let dataIn = Number(row);
        let ms: number = dataIn; // 100th to ms
        var day, hour, minute, seconds;
        seconds = Math.floor(ms / 100);
        minute = Math.floor(seconds / 60);
        seconds = seconds % 60;
        hour = Math.floor(minute / 60);
        minute = minute % 60;
        day = Math.floor(hour / 24);
        hour = hour % 24;
        if (day > 0) result += day + ' days, ';
        if (hour > 0) result += hour + ' hrs';
        if (minute > 0) result += ', ' + minute + ' mins';
        if (result.length === 0 && ms > 0) {
            result = '<1 s';
        } else if (result.length === 0) {
            result = '---';
        }
        return result;
    }

    public openDetails(container: string, severity?: number) {
        this.openSubcontainer = container;
        this.dashlet.displayHeader = false;
        this.openSubcontainerSeverity = severity;
    }

    public closeDetails(): void {
        this.openSubcontainer = null;
        this.dashlet.displayHeader = true;
    }

    public getNotAnsweredCalls(): number {
        return parseInt(this.dashlet.sipCallCounters.CallUnAnswered);
    }

    public getLicenceColour(licence: number): string {
        let top = 0;
        let bottom = 0;

        let x = this.dashlet.ribbonLicence;

        switch (licence) {
            case 1:
                if (
                    x.AvailableSIPCh !== '-' &&
                    x.SIPChannels !== '-' &&
                    x.AvailableSIPCh !== undefined &&
                    x.SIPChannels !== undefined &&
                    x.AvailableSIPCh !== '-1' &&
                    x.SIPChannels !== '-1'
                ) {
                    top = parseInt(x.SIPChannels) - parseInt(x.AvailableSIPCh);
                    bottom = parseInt(x.SIPChannels);
                } else {
                    return this.dashletService.getSeverityColor(0);
                }
                break;
            case 2:
                if (
                    x.AvailableEnhancedMediaSessionswithTranscodingCh !== '-' &&
                    x.EnhancedMediaSessionswithTranscodingChannels !== '-' &&
                    x.AvailableEnhancedMediaSessionswithTranscodingCh !== undefined &&
                    x.EnhancedMediaSessionswithTranscodingChannels !== undefined &&
                    x.AvailableEnhancedMediaSessionswithTranscodingCh !== '-1' &&
                    x.EnhancedMediaSessionswithTranscodingChannels !== '-1'
                ) {
                    top =
                        parseInt(x.EnhancedMediaSessionswithTranscodingChannels) -
                        parseInt(x.AvailableEnhancedMediaSessionswithTranscodingCh);
                    bottom = parseInt(x.EnhancedMediaSessionswithTranscodingChannels);
                } else {
                    return this.dashletService.getSeverityColor(0);
                }
                break;
            case 3:
                if (
                    x.AvailableSIPReg !== '-' &&
                    x.SIPRegistrations !== '-' &&
                    x.AvailableSIPReg !== undefined &&
                    x.SIPRegistrations !== undefined &&
                    x.AvailableSIPReg !== '-1' &&
                    x.SIPRegistrations !== '-1'
                ) {
                    top = parseInt(x.SIPRegistrations) - parseInt(x.AvailableSIPReg);
                    bottom = parseInt(x.SIPRegistrations);
                } else {
                    return this.dashletService.getSeverityColor(0);
                }
                break;
        }

        if (top === 0 && bottom === 0) {
            return this.dashletService.getSeverityColor(0);
        } else if (top / bottom < 0.8) {
            return this.dashletService.getSeverityColor(0);
        } else if (top / bottom >= 0.8 && top / bottom < 0.9) {
            return this.dashletService.getSeverityColor(1);
        } else if (top / bottom >= 0.9) {
            return this.dashletService.getSeverityColor(2);
        }
    }

    public getSIPSignallingSessions(): string {
        let x = this.dashlet.ribbonLicence;

        if (x.SIPChannels === '-1' || x.AvailableSIPCh === '-1') {
            return 'Unlimited';
        }
        x.SIPChannels === '-' || x.SIPChannels === undefined ? (x.SIPChannels = '0') : '';
        x.AvailableSIPCh === '-' || x.AvailableSIPCh === undefined ? (x.AvailableSIPCh = '0') : '';
        return parseInt(x.SIPChannels) - parseInt(x.AvailableSIPCh) + '/' + x.SIPChannels;
    }

    public getEnhancedMedia(): string {
        let x = this.dashlet.ribbonLicence;

        if (
            x.EnhancedMediaSessionswithTranscodingChannels === '-1' ||
            x.AvailableEnhancedMediaSessionswithTranscodingCh === '-1'
        ) {
            return 'Unlimited';
        }
        x.EnhancedMediaSessionswithTranscodingChannels === '-' ||
        x.EnhancedMediaSessionswithTranscodingChannels === undefined
            ? (x.EnhancedMediaSessionswithTranscodingChannels = '0')
            : '';
        x.AvailableEnhancedMediaSessionswithTranscodingCh === '-' ||
        x.AvailableEnhancedMediaSessionswithTranscodingCh === undefined
            ? (x.AvailableEnhancedMediaSessionswithTranscodingCh = '0')
            : '';
        return (
            parseInt(x.EnhancedMediaSessionswithTranscodingChannels) -
            parseInt(x.AvailableEnhancedMediaSessionswithTranscodingCh) +
            '/' +
            x.EnhancedMediaSessionswithTranscodingChannels
        );
    }

    public getSIPRegistration(): string {
        let x = this.dashlet.ribbonLicence;

        if (x.AvailableSIPReg === '-1' || x.SIPRegistrations === '-1') {
            return 'Unlimited';
        }
        x.AvailableSIPReg === '-' || x.AvailableSIPReg === undefined ? (x.AvailableSIPReg = '0') : '';
        x.SIPRegistrations === '-' || x.SIPRegistrations === undefined ? (x.SIPRegistrations = '0') : '';
        return parseInt(x.SIPRegistrations) - parseInt(x.AvailableSIPReg) + '/' + x.SIPRegistrations;
    }

    public getSignalGreen(data: any): number {
        return data.filter(
            signallingGroup => signallingGroup.AdminState === 'Enable' && signallingGroup.OperationalString === 'Up'
        ).length;
    }

    public getSignalAmber(data: any): number {
        return data.filter(signallingGroup => signallingGroup.AdminState !== 'Enable').length;
    }

    public getSignalRed(data): number {
        return data.filter(
            signallingGroup =>
                signallingGroup.AdminState === 'Enable' &&
                (signallingGroup.OperationalString === 'Down' ||
                    signallingGroup.OperationalString === 'UpDraining' ||
                    signallingGroup.OperationalString === 'UpDrained' ||
                    signallingGroup.OperationalString === 'UpPeersDown')
        ).length;
    }

    public getInterfacesUp(): number {
        let count = 0;
        if (this.dashlet.networkInterface !== null) {
            this.dashlet.networkInterface.forEach(item => {
                if (item.OperatorStatus === '0') {
                    count++;
                }
            });
        }
        return count;
    }

    public getInterfacesAmber(): number {
        let count = 0;
        if (this.dashlet.networkInterface !== null) {
            this.dashlet.networkInterface.forEach(item => {
                if (item.OperatorStatus === '1' && item.ConfigIEState === '0') {
                    count++;
                }
            });
        }
        return count;
    }

    public getInterfacesRed(): number {
        let count = 0;
        if (this.dashlet.networkInterface !== null) {
            this.dashlet.networkInterface.forEach(item => {
                if (item.OperatorStatus === '1' && item.ConfigIEState === '1') {
                    count++;
                }
            });
        }
        return count;
    }

    public getInterfaceGroupsList(mode: number): any[] {
        let result = [];
        if (this.dashlet.networkInterface !== null) {
            this.dashlet.networkInterface.forEach(item => {
                switch (mode) {
                    case 0:
                        //Up
                        if (item.OperatorStatus === '0') {
                            result.push(item);
                        }
                        break;
                    case 1:
                        //Config
                        if (item.OperatorStatus === '1' && item.ConfigIEState === '0') {
                            result.push(item);
                        }
                        break;
                    case 2:
                        //Down
                        if (item.OperatorStatus === '1' && item.ConfigIEState === '1') {
                            result.push(item);
                        }
                        break;
                }
            });
        }
        return result;
    }

    public showList(): string {
        if (this.processorMemory.systemInformation !== null) {
            let index = 0;
            let temp: string = ``;
            let codes = [
                this.getModel(this.processorMemory.systemInformation.Model),
                this.processorMemory.systemInformation.SoftwareBuildNumber,
                this.processorMemory.systemInformation.SWeId,
                this.processorMemory.systemInformation.SerialNumber
            ];

            for (let code of codes) {
                if (code !== '-') {
                    temp += `<span>${this.titles[index]} ${code}</span><br>`;
                }
                index++;
            }

            return temp;
        }
    }

    public getModel(model: string): string {
        switch (model) {
            case '1':
                return 'SBC 1000';
            case '2':
                return 'SBC 2000';
            case '3':
                return 'SBC SWe Lite';
        }
    }

    public getInterfaceBorderColor(data): string {
        if (data.OperatorStatus === '0') {
            return '5px solid #0A9B02';
        } else if (data.OperatorStatus === '1' && data.ConfigIEState === '0') {
            return '5px solid #FFA500';
        } else if (data.OperatorStatus === '1' && data.ConfigIEState === '1') {
            return '5px solid #DC3704';
        }
    }

    public getDSPList(mode: number) {
        let list = [];
        let check = '';
        mode === 0 ? (check = '1') : (check = '0');

        this.dashlet.dspList.forEach(dsp => {
            if (dsp.ServiceStatus === check) {
                list.push(dsp);
            }
        });
        return list;
    }

    public getAvailableDSP(): number {
        let available = 0;

        this.dashlet.dspList.forEach(dsp => {
            if (dsp.Available === '1') {
                available++;
            }
        });

        return available;
    }

    public getDSPStatusUp(): number {
        let up = 0;

        this.dashlet.dspList.forEach(dsp => {
            if (dsp.ServiceStatus === '1') {
                up++;
            }
        });

        return up;
    }

    public getDSPStatusDown(): number {
        let down = 0;

        this.dashlet.dspList.forEach(dsp => {
            if (dsp.ServiceStatus === '0') {
                down++;
            }
        });

        return down;
    }

    public getSpeedEnum(mode): string {
        switch (mode) {
            case '0':
                return '10 MB';
            case '1':
                return '100 MB';
            case '2':
                return '1 GB';
            case '3':
                return 'Auto';
            default:
                return 'Unknown';
        }
    }

    public getNetworkMode(mode): string {
        switch (mode) {
            case '0':
                return 'Switch';
            case '1':
                return 'Route';
            default:
                return 'Unknown';
        }
    }

    public getTimingMode(mode): string {
        switch (mode) {
            case '0':
                return 'Auto';
            case '1':
                return 'Master';
            case '2':
                return 'Slave';
        }
    }

    public getDuplexMode(mode): string {
        switch (mode) {
            case '0':
                return 'Half';
            case '1':
                return 'Full';
            case '2':
                return 'Auto';
        }
    }

    public enumAvailable(mode): string {
        switch (mode) {
            case '0':
                return 'No';
            case '1':
                return 'Yes';
        }
    }

    public convertCPU(cpu: string): string {
        let cpuNum = parseInt(cpu) / 100;
        return cpuNum.toFixed(1) + '%';
    }

    public enumStatus(mode): string {
        switch (mode) {
            case '0':
                return 'Down';
            case '1':
                return 'Up';
        }
    }

    public enumMohStatus(mode): string {
        switch (mode) {
            case '0':
                return 'Loaded';
            case '1':
                return 'Not Loaded';
            case '2':
                return 'Load Failed';
            case '3':
                return 'Loading';
            case '4':
                return 'Load Aborted';
        }
    }

    public enumModule(mode): string {
        switch (mode) {
            case '0':
                return 'Unknown';
            case '1':
                return 'DS1_2SPAN_LineCard';
            case '2':
                return 'DS1_4SPAN_LineCard';
            case '3':
                return 'DS1_8SPAN_LineCard';
            case '4':
                return 'EX_LineCard';
            case '5':
                return 'MSPD_C910';
            case '6':
                return 'MSPD_C349';
            case '7':
                return 'TI_6486_DaughterBoard';
            case '8':
                return 'BITS_DaughterBoard';
            case '9':
                return 'WAN_DaughterBoard';
            case '10':
                return 'SFP_DaughterBoard';
            case '11':
                return 'COMExpress_Module';
            case '12':
                return 'MainBoard';
            case '13':
                return 'Chassis';
            case '14':
                return 'PSU';
            case '15':
                return 'Not_Installed';
            case '16':
                return 'Node';
            case '17':
                return 'FXS_8PORT_LineCard';
            case '18':
                return 'FXS_16PORT_LineCard';
            case '19':
                return 'FXS_24PORT_LineCard';
            case '20':
                return 'FXS_4PORT_Lower_DaughterBoard';
            case '21':
                return 'FXS_4PORT_Upper_DaughterBoard';
            case '22':
                return 'FXO_4PORT_Lower_DaughterBoard';
            case '23':
                return 'FXO_4PORT_Upper_DaughterBoard';
            case '24':
                return 'BRI_4PORT_DaughterBoard';
            case '25':
                return 'DS1_1SPAN_DaughterBoard';
            case '26':
                return 'DS1_2SPAN_DaughterBoard';
            case '27':
                return 'FXS_4PORT_NR_Lower_Daughter_Board';
            case '28':
                return 'MSPD_C346_DaughterBoard';
            case '29':
                return 'MAX';
        }
    }
}
