<div class="flex-column gap-l" *ngIf="!openSubcontainer">

    <ng-container *ngIf="dashlet.showProcessoryMemory">
        <ng-container *ngTemplateOutlet="systemResources"></ng-container>
    </ng-container>
    <ng-container *ngTemplateOutlet="networkConnectivityMetrics"> </ng-container>
    <div *ngIf="dashlet.showNetworkInterface">
        <ng-container *ngTemplateOutlet="networkInterface"> </ng-container>
    </div>
    <div *ngIf="dashlet.showSignallingGroups">
        <ng-container *ngTemplateOutlet="signallingGroup"> </ng-container>
    </div>
    <div *ngIf="dashlet.showLicenceOccupied">
        <ng-container *ngTemplateOutlet="licence"> </ng-container>
    </div>
    <div *ngIf="dashlet.showDSPSection">
        <ng-container *ngTemplateOutlet="dsp"> </ng-container>
    </div>
    <div *ngIf="dashlet.showCallCounters">
        <ng-container *ngTemplateOutlet="callCounter"> </ng-container>
    </div>
</div>

<ng-template #systemResources>
    <dashlet-section-processor-memory
        #processorMemory
        [showSystemChart]="dashlet.showSystemChart"
        [showDiskUsage]="false"
        [equipment]="dashlet.equipmentConnectivity"
        [customerId]="dashlet.customer.customerId"
        [locationId]="dashlet.location.locationId"
        [commandTypeIdCPUUsage]="dashlet.COMMAND_TYPE_ID_PROCESSOR_MEMORY"
        [commandTypeIdMemoryUsage]="dashlet.COMMAND_TYPE_ID_PROCESSOR_MEMORY"
        [commandTypeIdUpTime]="dashlet.COMMAND_TYPE_ID_UPTIME_LOGGED_IN_USERS"
        [commandTypeIdSystemInfo]="dashlet.COMMAND_TYPE_ID_SYSTEM_INFO"
    >
    </dashlet-section-processor-memory>
</ng-template>

<ng-template #networkConnectivityMetrics>
    <dashlet-section-network-connectivity
        [showNetworkGraph]="dashlet.showNetworkChart"
        [equipment]="dashlet.equipmentConnectivity"
        [customerId]="dashlet.customer.customerId"
    >
    </dashlet-section-network-connectivity>
</ng-template>

<ng-template #signallingGroup>
    <dashlet-section-header>Signalling Groups</dashlet-section-header>
    <div class="flex-column gap-m">
        <dashlet-ratio-bar class="dashlet-cell clickable" (click)="openDetails('signalling-groups-drilldown')">
            <dashlet-ratio-bar-item [value]="getSignalGreen(dashlet.signallingStates)" [color]="getSeverityColor(0)"></dashlet-ratio-bar-item>
            <dashlet-ratio-bar-item [value]="getSignalAmber(dashlet.signallingStates)" [color]="getSeverityColor(1)"></dashlet-ratio-bar-item>
            <dashlet-ratio-bar-item [value]="getSignalRed(dashlet.signallingStates)" [color]="getSeverityColor(2)"></dashlet-ratio-bar-item>
        </dashlet-ratio-bar>
        <div class="justify-space-between">
            <div class="dashlet-link-underline flex-column align-center mat-h2" dashlet-link="true" (click)="openDetails('signalling-groups-drilldown')">
                <span class="mat-body-2 text-no-wrap"> Configured</span>
                {{ dashlet.signallingStates.length }}
            </div>
            <div class="dashlet-link-underline flex-column align-center mat-h2" dashlet-link="true" (click)="openDetails('signalling-groups-drilldown')">
                <span class="mat-body-2 text-no-wrap">Admin State Enabled</span>
                {{ dashlet.adminStateEnabledCount }}
            </div>
            <div class="dashlet-link-underline flex-column align-center mat-h2" dashlet-link="true" (click)="openDetails('signalling-groups-drilldown')">
                <span class="mat-body-2 text-no-wrap">Op State Down</span>
                {{ dashlet.operationalStateDownCount }}
            </div>
        </div>
    </div>
</ng-template>

<ng-template #licence>
    <dashlet-section-header>License</dashlet-section-header>
    <div class="flex grow justify-space-around">
        <div class="metric gap-s" [ngStyle]="{ 'border-left-color': getLicenceColour(1) }">
            <div class="mat-caption">SIP Signalling Sessions</div>
            <div class="mat-h2 dashlet-link-underline">{{ getSIPSignallingSessions() }}</div>
        </div>

        <div class="metric gap-s" [ngStyle]="{ 'border-left-color': getLicenceColour(2) }">
            <div class="mat-caption">Enhanced Media</div>
            <div class="mat-h2 dashlet-link-underline">{{ getEnhancedMedia() }}</div>
        </div>
        <div class="metric gap-s" [ngStyle]="{ 'border-left-color': getLicenceColour(3) }">
            <div class="mat-caption">SIP Registration</div>
            <div class="mat-h2 dashlet-link-underline">{{ getSIPRegistration() }}</div>
        </div>
    </div>
</ng-template>

<ng-template #dsp>
    <dashlet-section-header>DSP</dashlet-section-header>
    <div class="flex-column gap-m">
        <dashlet-ratio-bar class="dashlet-cell">
            <dashlet-ratio-bar-item [value]="getDSPStatusUp()" [color]="getSeverityColor(0)"></dashlet-ratio-bar-item>
            <dashlet-ratio-bar-item [value]="getDSPStatusDown()" [color]="getSeverityColor(2)"></dashlet-ratio-bar-item>
        </dashlet-ratio-bar>
        <div class="justify-space-between">
            <h3 dashlet-link="true" (click)="openDetails('dsp-drilldown', 0)">
                
                {{ getAvailableDSP() }}
                <span class="mat-h3"> DSP Modules</span>
            </h3>
            <h3 dashlet-link="true" (click)="openDetails('dsp-drilldown', 0)">
                
                {{ getDSPStatusUp() }}
                <span class="mat-h3"> Up</span>
            </h3>
            <h3 dashlet-link="true" (click)="openDetails('dsp-drilldown', 2)">
                
                {{ getDSPStatusDown() }}
                <span class="mat-h3">Down</span>
            </h3>
        </div>
    </div>

    
</ng-template>

<ng-template #callCounter>
        <dashlet-section-header>Global Call Counters</dashlet-section-header>
        <div class="flex justify-space-between align-baseline">
        <div class="flex gap-m">
            <div class="flex-column text-align-right equipment-detail-labels">
                <div class="mat-body-2 dashlet-link-underline" (click)="openDetails('call-counter-drilldown')">Currently Up</div>
                <div class="mat-body-2 dashlet-link-underline" (click)="openDetails('call-counter-drilldown')">Currently Triansient</div>
                <div class="mat-body-2 dashlet-link-underline" (click)="openDetails('call-counter-drilldown')">Attempted</div>
                <div class="mat-body-2 dashlet-link-underline" (click)="openDetails('call-counter-drilldown')">Succeeded</div>
                
                <div class="mat-body-2 dashlet-link-underline" (click)="openDetails('call-counter-drilldown')">Failed</div>
                <div class="mat-body-2 dashlet-link-underline" (click)="openDetails('call-counter-drilldown')">Not Answered</div>
            </div>
            <div class="flex-column equipment-detail-values">
                <div class="mat-body-1">{{ dashlet.sipCallCounters.CallCurrentlyUp || '---' }}</div>
                <div class="mat-body-1">{{ dashlet.sipCallCounters.CallCurrentlyTransient || '---' }}</div>
                
                <div class="mat-body-1">{{ dashlet.sipCallCounters.CallAttempts || '---' }}</div>
                <div class="mat-body-1">{{ dashlet.sipCallCounters.CallSucceeded|| '---' }}</div>
                
                <div class="mat-body-1">{{ dashlet.sipCallCounters.CallFailed|| '---' }}</div>
                <div class="mat-body-1">{{  getNotAnsweredCalls()|| '---' }}</div>
                
                
            </div>
        </div>
        <div class="flex gap-m">
            <div class="flex-column text-align-right equipment-detail-labels">
                <div class="mat-body-2 dashlet-link-underline" (click)="openDetails('call-counter-drilldown')">Internal Glare</div>
                <div class="mat-body-2 dashlet-link-underline" (click)="openDetails('call-counter-drilldown')">External Glare </div>
                <div class="mat-body-2 dashlet-link-underline" (click)="openDetails('call-counter-drilldown')">No Signalling Group</div>
            </div>
            <div class="flex-column equipment-detail-values">
                <div class="mat-body-1">{{   dashlet.sipCallCounters.InternalGlares || '---' }}</div>
                
                <div class="mat-body-1">{{dashlet.sipCallCounters.ExternalGlares || '---' }}</div>
                
                <div class="mat-body-1">{{dashlet.sipCallCounters.CallAbandonedNoTrunk || '---' }}</div>
            </div>
        </div>
    </div>
 
    
</ng-template>

<ng-template #networkInterface>
    <dashlet-section-header>Network Interface</dashlet-section-header>
    <div class="flex-column gap-m">
        <dashlet-ratio-bar class="dashlet-cell clickable">
            <dashlet-ratio-bar-item
                [value]="getInterfacesUp()"
                [color]="getSeverityColor(0)"
                (click)="openDetails('network-interface-drilldown', 0)"
            ></dashlet-ratio-bar-item>
            <dashlet-ratio-bar-item
                [value]="getInterfacesAmber()"
                [color]="getSeverityColor(1)"
                (click)="openDetails('network-interface-drilldown', 1)"
            ></dashlet-ratio-bar-item>
            <dashlet-ratio-bar-item
                [value]="getInterfacesRed()"
                [color]="getSeverityColor(2)"
                (click)="openDetails('network-interface-drilldown', 2)"
            ></dashlet-ratio-bar-item>
        </dashlet-ratio-bar>
        <div class="justify-space-between">
            <h3 class="dashlet-link-underline" dashlet-link="true" (click)="openDetails('network-interface-drilldown', 0)">
                {{ getInterfacesUp() }}
                <span class="mat-h3">Up</span>
            </h3>
            <h3 class="dashlet-link-underline" dashlet-link="true" (click)="openDetails('network-interface-drilldown', 1)">
                {{ getInterfacesAmber() }}
                <span class="mat-h3">Admin Status Down</span>
            </h3>
            <h3 class="dashlet-link-underline" dashlet-link="true" (click)="openDetails('network-interface-drilldown', 2)">
                {{ getInterfacesRed() }}
                <span class="mat-h3">Down</span>
            </h3>
        </div>
    </div>
</ng-template>

<vsm-dashlet-subcontainer
    *ngIf="openSubcontainer === 'network-interface-drilldown'"
    [title]="dashlet.location.locationName + ' | ' + dashlet.equipment.equipmentName"
    [navPrevious]="'Ribbon SBC Edge Interfaces - ' + dashlet.customer.customerName"
    [navCurrent]="'Network Interface Breakdown'"
    [color]="getSeverityColor(this.openSubcontainerSeverity)"
    (close)="closeDetails()"
>
    <div class="flex-column gap-m" subtitle>
        Network Interface Drilldown

        <span *ngIf="this.openSubcontainerSeverity === 0">
            {{ getInterfacesUp() + ' of ' + (getInterfacesUp() + getInterfacesAmber() + getInterfacesRed()) + ' Up' }}
        </span>
        <span *ngIf="this.openSubcontainerSeverity === 1">
            {{ getInterfacesAmber() + ' of ' + (getInterfacesUp() + getInterfacesAmber() + getInterfacesRed()) + ' Admin Down' }}
        </span>
        <span *ngIf="this.openSubcontainerSeverity === 2">
            {{ getInterfacesRed() + ' of ' + (getInterfacesUp() + getInterfacesAmber() + getInterfacesRed()) + ' Operational Down' }}
        </span>
    </div>

    <ng-container body>
        <mat-accordion multi>
            <mat-expansion-panel *ngFor="let interface of getInterfaceGroupsList(this.openSubcontainerSeverity)">
                <mat-expansion-panel-header>
                    <mat-panel-title> {{ interface.Name }} </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="flex gap-m">
                    <div class="flex-column text-align-right equipment-detail-labels">
                        <div class="mat-body-2">Mac Address</div>
                        <div class="mat-body-2">Speed</div>
                        <div class="mat-body-2">Duplex Mode</div>
                        <div class="mat-body-2">Interfaceing Networkin Mode</div>
                        <div class="mat-body-2">Gigabit Timing Mode</div>
                    </div>
                    <div class="flex-column equipment-detail-values">
                        <div class="mat-body-1">{{ interface.HwAddress || '---' }}</div>
                        <div class="mat-body-1">{{ getSpeedEnum(interface.Speed) || '---' }}</div>
                        <div class="mat-body-1">{{ getDuplexMode(interface.Duplex) || '---' }}</div>
                        <div class="mat-body-1">{{ getNetworkMode(interface.NetworkingMode) || '---' }}</div>
                        <div class="mat-body-1">{{ getTimingMode(interface.TimingMode) || '---' }}</div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-container>
    <div footer class="justify-end grow gap-m">
        <button mat-button [disabled]="openSubcontainerSeverity === 0" matTooltip="View OK Interfaces" (click)="openDetails('network-interface-drilldown', 0)">
            Up
        </button>
        <button
            mat-button
            [disabled]="openSubcontainerSeverity === 1"
            matTooltip="View Admin-Down Interfaces"
            (click)="openDetails('network-interface-drilldown', 1)"
        >
            Admin Down
        </button>
        <button
            mat-button
            [disabled]="openSubcontainerSeverity === 2"
            matTooltip="View Down Interfaces"
            (click)="openDetails('network-interface-drilldown', 2)"
        >
            Operational-Down
        </button>
    </div>
</vsm-dashlet-subcontainer>

<vsm-dashlet-subcontainer
    *ngIf="openSubcontainer === 'signalling-groups-drilldown'"
    [title]="dashlet.location.locationName + ' | ' + dashlet.equipment.equipmentName"
    [navPrevious]="'Ribbon SBC Edge System Health - ' + dashlet.customer.customerName"
    [navCurrent]="'Signalling Groups Breakdown'"
    (close)="closeDetails()"
>
    <ng-container subtitle> Signalling Groups Breakdown </ng-container>
    <ng-container body>
        <mat-accordion>
            <mat-expansion-panel *ngFor="let signallingGroup of dashlet.signallingStates">
                <mat-expansion-panel-header>
                    <mat-panel-title> {{ signallingGroup.C2TToFromDialMSTeams }} </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="flex gap-m">
                    <div class="flex-column text-align-right equipment-detail-labels">
                        <div class="mat-body-2">Admin State</div>
                        <div class="mat-body-2">Operational State</div>
                        <div class="mat-body-2">Federated FQDN</div>
                        <div class="mat-body-2">Used Channels</div>
                    </div>
                    <div class="flex-column equipment-detail-values">
                        <div class="mat-body-1">{{ signallingGroup.AdminState || '---' }}</div>
                        <div class="mat-body-1">{{ signallingGroup.OperationalString || '---' }}</div>
                        <div class="mat-body-1">{{ signallingGroup.FederatedFQDN || '---' }}</div>
                        <div class="mat-body-1">
                            {{ signallingGroup.CurrentCalls || '---' }} of
                            {{ signallingGroup.NumberOfChannels || '---' }}
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-container>
</vsm-dashlet-subcontainer>

<vsm-dashlet-subcontainer
    *ngIf="openSubcontainer === 'dsp-drilldown'"
    [title]="dashlet.location.locationName + ' | ' + dashlet.equipment.equipmentName"
    [navPrevious]="'Ribbon SBC Edge System Health - ' + dashlet.customer.customerName"
    [navCurrent]="'DSP Breakdown'"
    (close)="closeDetails()"
    [color]="getSeverityColor(this.openSubcontainerSeverity)"
>
<div class="flex-column gap-m" subtitle>
        DSP Breakdown
        <br />
        <span *ngIf="this.openSubcontainerSeverity === 0"> {{ getDSPStatusUp() }} of {{ getAvailableDSP() }} up </span>
        <span *ngIf="this.openSubcontainerSeverity === 2"> {{ getDSPStatusDown() }} of {{ getAvailableDSP() }} down </span>
</div>
    <ng-container body>
        <mat-accordion>
            <mat-expansion-panel *ngFor="let dsp of getDSPList(this.openSubcontainerSeverity)">
                <mat-expansion-panel-header>
                    <mat-panel-title> DSP Slot {{ dsp.CardId }}  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="flex gap-m">
                    <div class="flex-column text-align-right equipment-detail-labels">
                        <div class="mat-body-2">Available</div>
                        <div class="mat-body-2">Module Type</div>
                        <div class="mat-body-2">Channels in Use</div>
                        <div class="mat-body-2">CPU Usage </div>
                        
                        <div class="mat-body-2">Service Status </div>
                        <div class="mat-body-2">MoH File Status </div>
                    </div>
                    <div class="flex-column equipment-detail-values">
                        <div class="mat-body-1">{{enumAvailable(dsp.Available)|| '---' }}</div>
                        <div class="mat-body-1">{{  enumModule(dsp.CardType)|| '---' }}</div>
                        <div class="mat-body-1">{{ dsp.ChannelsInUse|| '---' }}</div>
                        
                        <div class="mat-body-1">{{convertCPU(dsp.CPUUsage)  || '---' }}</div>
                        
                        <div class="mat-body-1">{{ enumStatus(dsp.ServiceStatus)|| '---' }}</div>
                        
                        <div class="mat-body-1">{{ enumMohStatus(dsp.MohStatus) || '---' }}</div>
                        
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        
    </ng-container>
   
    <div footer class="justify-end grow gap-m">
        <button mat-button [disabled]="openSubcontainerSeverity === 0" matTooltip="View Up" (click)="openDetails('dsp-drilldown', 0)">
            Up
        </button>
        <button
            mat-button
            [disabled]="openSubcontainerSeverity === 2"
            matTooltip="View Down"
            (click)="openDetails('dsp-drilldown', 2)"
        >
           Down
        </button>
       
    </div>
</vsm-dashlet-subcontainer>

<vsm-dashlet-subcontainer
    *ngIf="openSubcontainer === 'call-counter-drilldown'"
    [title]="dashlet.location.locationName + ' | ' + dashlet.equipment.equipmentName"
    [navPrevious]="'Ribbon SBC Edge System Health - ' + dashlet.customer.customerName"
    [navCurrent]="'Call Counter Breakdown'"
    (close)="closeDetails()"
>
    <ng-container subtitle> Call Counter Breakdown </ng-container>
    <ng-container body>
        <mat-accordion>
            <mat-expansion-panel *ngFor="let drillDownInfo of dashlet.sipCallCountersDrilldown">
                <mat-expansion-panel-header>
                    <mat-panel-title> {{ drillDownInfo.C2TToFromDialMSTeams }}  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="flex-column gap-m">
                <div class="flex gap-m">
                    <div class="flex-column text-align-right equipment-detail-labels">
                        <div class="mat-body-2">Curent Calls</div>
                        <div class="mat-body-2">Total Calls</div>
                        <div class="mat-body-2">Conencted Calls</div>
                        <div class="mat-body-2">Refused Calls</div>
                        
                        <div class="mat-body-2">Errored Calls</div>
                        <div class="mat-body-2">Blocked Calls</div>
                        <br>
                        <div class="mat-body-2"> Egress Curent Calls</div>
                            <div class="mat-body-2"> Egress Calls Attempts</div>
                            <div class="mat-body-2"> Egress Completed Calls</div>
                            <div class="mat-body-2"> Egress Rejected Calls</div>
                            
                            <div class="mat-body-2"> Egress Blocked Calls</div>
                            <br>
                            <div class="mat-body-2"> Ingress Curent Calls</div>
                            <div class="mat-body-2"> Ingress Calls Attempts</div>
                            <div class="mat-body-2"> Ingress Completed Calls</div>
                            <div class="mat-body-2"> Ingress Rejected Calls</div>
                            
                            <div class="mat-body-2"> Ingress Blocked Calls</div>
                    </div>
                    <div class="flex-column equipment-detail-values">
                        <div class="mat-body-1">{{ drillDownInfo.CurrentCalls || '---' }}</div>
                        <div class="mat-body-1">{{  drillDownInfo.TotalCalls|| '---' }}</div>
                        <div class="mat-body-1">{{ drillDownInfo.ConnectedCalls|| '---' }}</div>
                        
                        <div class="mat-body-1">{{drillDownInfo.RefusedCalls  || '---' }}</div>
                        
                        <div class="mat-body-1">{{ drillDownInfo.ErroredCalls || '---' }}</div>
                        
                        <div class="mat-body-1">{{ drillDownInfo.BlockedCalls  || '---' }}</div>
                        <br>
                        
                        <div class="mat-body-1">{{ drillDownInfo.EgressCurrentCalls || '---' }}</div>
                        <div class="mat-body-1">{{  drillDownInfo.EgressCallAttempts|| '---' }}</div>
                        <div class="mat-body-1">{{ drillDownInfo.EgressCompletedCalls|| '---' }}</div>
                        
                        <div class="mat-body-1">{{drillDownInfo.EgressRejectedCalls  || '---' }}</div>
                        
                        
                        <div class="mat-body-1">{{ drillDownInfo.EgressBlockedCalls  || '---' }}</div>
                        <br>
                        <div class="mat-body-1">{{ drillDownInfo.IngressCurrentCalls || '---' }}</div>
                        <div class="mat-body-1">{{  drillDownInfo.IngressCallAttempts|| '---' }}</div>
                        <div class="mat-body-1">{{ drillDownInfo.IngressCompletedCalls|| '---' }}</div>
                        
                        <div class="mat-body-1">{{drillDownInfo.IngressRejectedCalls  || '---' }}</div>
                        
                        
                        <div class="mat-body-1">{{ drillDownInfo.IngressBlockedCalls  || '---' }}</div>
                    </div>
                </div>
                    
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        
    </ng-container>
</vsm-dashlet-subcontainer>
