import { AccountService, DashletService, DashletSettingsService, RealtimeGatewayService } from '@services/index';
import { Customer, Dashlet } from '@models/index';
import { Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

export interface SipGraphData {
    blue: { x: Date; y: number }[];
    green: { x: Date; y: number }[];
    amber: { x: Date; y: number }[];
    multiEquipData: { equipmentName: string; equipId: string; aiData: AIDataPoint }[];
    red: { x: Date; y: number }[];
}

export interface AIDataPoint {
    aiDetected: {
        x: Date;
        y: number;
        aiDataPoint: AzureAiLastDetect;
    }[];
    nonAiDetected: {
        x: Date;
        y: number;
        aiDataPoint: AzureAiLastDetect;
    }[];
    upperMargin: {
        x: Date;
        y: number;
        aiDataPoint: AzureAiLastDetect;
    }[];
    lowerMargin: {
        x: Date;
        y: number;
        aiDataPoint: AzureAiLastDetect;
    }[];
}

export interface AzureAiLastDetect {
    ExpectedValue: number;
    IsAnomaly: string;
    IsNegativeAnomaly: boolean;
    IsPositiveAnomaly: boolean;
    LowerMargin: number;
    Period: number;
    SuggestedWindow: number;
    UTCDateTime: Date;
    UpperMargin: number;
    EquipmentId: string;
    EquipmentName: string;
    ActualCount: number;
    NoCalc?: boolean;
}
[];

export interface SipSummaryData {
    response: string;
    sessions: number;
    percentage: number;
    distribution?: Distribution;
    expansion?: SipSummaryData[];
}

export interface SipMessageData {
    message: string;
    total: number;
    percentage: number;
    distribution?: DistributionSection[];
    expansion?: SipSummaryData[];
}

export interface SipResponses {
    information: SipSummaryData[];
    success: SipSummaryData[];
    client: SipSummaryData[];
    server: SipSummaryData[];
}
export interface MessageResponses {
    ACK: ResponseCount[];
    BYE: ResponseCount[];
    CANCEL: ResponseCount[];
    INFO: ResponseCount[];
    INVITE: ResponseCount[];
    MESSAGE: ResponseCount[];
    NOTIFY: ResponseCount[];
    OPTIONS: ResponseCount[];
    PRACK: ResponseCount[];
    PUBLISH: ResponseCount[];
    REFER: ResponseCount[];
    REGISTER: ResponseCount[];
    SUBSCRIBE: ResponseCount[];
    UPDATE: ResponseCount[];
}
export interface ResponseCount {
    response: string;
    count: number;
}

export interface Distribution {
    first: DistributionSection;
    second: DistributionSection;
    other: DistributionSection;
}

export interface DistributionSection {
    title: string;
    value: number;
    colour: string;
}

export class DashletSIPResponseSummary extends Dashlet {
    public tempAiData4xx: any[] = [];
    public tempAiData5xx: any[] = [];
    public tempAiData6xx: any[] = [];

    public anomalyData4xx: AzureAiLastDetect[] = [];
    public anomalyData5xx: AzureAiLastDetect[] = [];
    public anomalyData6xx: AzureAiLastDetect[] = [];

    private _chartDataUpdated: Subject<null> = new Subject();
    public loadingAIData = false;
    public locationId: any;
    public documentTimeStamps: any[] = [];
    public settingsUpdated: Subject<null> = new Subject();

    sipDataUpdated: Subject<null> = new Subject();
    sipDataUpdating: Subject<null> = new Subject();
    equipmentId: any;
    subs: Subscription[] = [];
    graphData: SipGraphData;
    graphData4xx: SipGraphData;
    graphData5xx: SipGraphData;
    graphData6xx: SipGraphData;
    summaryData: SipSummaryData[];
    messageData: SipMessageData[];
    hourSetting: number = 24;
    responsesByMessage: MessageResponses;
    customerId: string;
    customerName: string;
    customer: Customer;
    location: string;
    equipment: any;
    lastUpdated: Date;
    expandedData: SipResponses;

    public showAi: Subject<boolean> = new Subject<boolean>();

    get onChartDataUpdated() {
        return this._chartDataUpdated;
    }
    sipRequestNames: string[] = [
        'ACK',
        'BYE',
        'CANCEL',
        'INFO',
        'INVITE',
        'MESSAGE',
        'NOTIFY',
        'OPTIONS',
        'PRACK',
        'PUBLISH',
        'REFER',
        'REGISTER',
        'SUBSCRIBE',
        'UPDATE'
    ];
    public originalData: any[];
    private count = 1;
    public ONE_HOUR_SUMMARY_COMMAND_TYPE_ID: string = 'E064DDBF-0605-47A0-B92E-70FAE41256A4';
    public AZURE_AI_ANOMALY_LAST_DETECT_4XX_COMMAND_TYPE_ID: string = 'EF4F4C6D-70E0-43D0-B971-7D5A859B7E5B';
    public AZURE_AI_ANOMALY_LAST_DETECT_5XX_COMMAND_TYPE_ID: string = 'F2677E3A-B343-4E08-8768-48DD0884A97B';
    public AZURE_AI_ANOMALY_LAST_DETECT_6XX_COMMAND_TYPE_ID: string = 'E7AA83C4-8F49-4676-A9E9-DBF692872BF3';

    //Region Constructor
    constructor(
        private realtimeService: RealtimeGatewayService,
        private settingsService: DashletSettingsService,
        private accountService: AccountService,
        private dashletService: DashletService
    ) {
        super();

        this.lastUpdated = new Date();

        this.graphData = {
            amber: [],
            blue: [],
            green: [],
            multiEquipData: [],
            red: []
        };

        this.graphData4xx = {
            amber: [],
            blue: [],
            green: [],
            multiEquipData: [],
            red: []
        };

        this.graphData5xx = {
            amber: [],
            blue: [],
            green: [],
            multiEquipData: [],
            red: []
        };
        this.graphData6xx = {
            amber: [],
            blue: [],
            green: [],
            multiEquipData: [],
            red: []
        };

        this.originalData = [];
        this.resetSummaryData();
        //sizing
        this.sizes = [
            {
                id: 1,
                label: 'Small',
                cols: 8,
                rows: 5
            },
            {
                id: 0,
                label: 'Large',
                cols: 8,
                rows: 11
            },
            {
                id: 2,
                label: 'Huge (AI)',
                cols: 16,
                rows: 11
            }
        ];
        this.applySize(0);

        //init data
        this.resetData();
    }

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);

        //unsub realtime feed
        const user = this.accountService.getUserDetails();
        this.customerId = v.customer ? v.customer.value : user.EntityId;
        this.customer = v.customer ? v.customer.label : user.UsersEntityName;
        //read settings object
        this.configured = v.customer && v.location && v.equipment;
        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
        } else {
            this.customer = new Customer('', '');
        }

        if (this.configured) {
            if (
                (v.equipment && v.equipment.length >= 0) ||
                (v.location && v.location.value && this.locationId !== v.location.value)
            ) {
                this.equipmentId = v.equipment;
                this.equipment = v.equipment;
                this.location = v.location.label;
                this.locationId = v.location.value;
                this.settingsUpdated.next(null);
            }
            this.refreshNameTag();

            this.customNameTag = v.nameTag;
            //sub realtime feed
        }

        //update size
        this.updateSize();
    }
    refreshNameTag() {
        if (this.lastUpdated === undefined) {
            //We have no data for last updated yet
            return;
        }
        //Make new dates, one without minutes for period
        let originalDate = new Date(this.lastUpdated),
            originalDateNoMinutes = new Date(this.lastUpdated);
        originalDateNoMinutes.setMinutes(0, 0, 0);

        let _month = originalDate.toLocaleDateString('en-nz', { month: 'short' });
        let _date = originalDate.toLocaleDateString('en-nz', { day: 'numeric' });
        let _time = originalDateNoMinutes
            .toLocaleTimeString('en-nz', { hour: 'numeric', minute: 'numeric' })
            .toUpperCase();

        //Date for first value in period
        let firstDate: Date = new Date(originalDateNoMinutes);
        firstDate.setHours(firstDate.getHours() - this.hourSetting);

        let _firstDate = firstDate.toLocaleDateString('en-nz', { day: 'numeric' });
        let _firstMonth = firstDate.toLocaleDateString('en-nz', { month: 'short' });
        let _firstTime = firstDate.toLocaleTimeString('en-nz', { hour: 'numeric', minute: 'numeric' }).toUpperCase();

        let period: string = '';

        if (this.hourSetting === 24) {
            period = _firstDate + '-' + _firstMonth + ' ' + _firstTime + ' - ' + _date + '-' + _month + ' ' + _time;
        } else {
            period = _firstTime + ' - ' + _time;
        }
        if (this.configured) {
            let _hour = originalDate
                .toLocaleTimeString('en-nz', { hour: 'numeric', minute: 'numeric', timeZoneName: 'short' })
                .toUpperCase();
            let equipName = '';
            if (this.equipment.length) {
                equipName = this.equipment.map(e => e.label).join(',');
            }
            this.generatedNameTag = `${this.location} | ${equipName} | Last updated at ${_date}-${_month} ${_hour}`;
        } else {
            this.generatedNameTag = 'Unconfigured';
        }
    }

    public applySize(id: number): void {
        super.applySize(id);
        this.showAi.next(id === 2);
        this.updateSize();
    }

    private updateSize() {
        let h = 0;
        let w = 0;
        this.applySizeExpansion(w, h);
    }

    public getStorageArray(commandTypeID: string): AzureAiLastDetect[] {
        switch (commandTypeID) {
            case this.AZURE_AI_ANOMALY_LAST_DETECT_4XX_COMMAND_TYPE_ID:
                return this.anomalyData4xx;
            case this.AZURE_AI_ANOMALY_LAST_DETECT_5XX_COMMAND_TYPE_ID:
                return this.anomalyData5xx;
            case this.AZURE_AI_ANOMALY_LAST_DETECT_6XX_COMMAND_TYPE_ID:
                return this.anomalyData6xx;
        }
    }

    public getGraphDataObject(commandTypeID: string): SipGraphData {
        switch (commandTypeID) {
            case this.AZURE_AI_ANOMALY_LAST_DETECT_4XX_COMMAND_TYPE_ID:
                return this.graphData4xx;
            case this.AZURE_AI_ANOMALY_LAST_DETECT_5XX_COMMAND_TYPE_ID:
                return this.graphData5xx;
            case this.AZURE_AI_ANOMALY_LAST_DETECT_6XX_COMMAND_TYPE_ID:
                return this.graphData6xx;
        }
    }

    private historicRequestForSingleEquip(commandTypeID: string): void {
        this.realtimeService.requestHistoricDate(
            this.equipmentId,
            commandTypeID,
            new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
            new Date().toISOString(),
            this.hourSetting
        );
    }

    private historicRequestForAllEquipment(commandTypeID: string): void {
        this.settingsService
            .getEquipment(this.locationId, 'asm')
            .pipe(take(1))
            .subscribe(equipments => {
                var keys = Object.keys(equipments);
                keys.forEach(key => {
                    this.realtimeService.requestHistoricDate(
                        equipments[key].value,
                        commandTypeID,
                        new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
                        new Date().toISOString(),
                        this.hourSetting
                    );
                });
            });

        this.settingsService
            .getEquipment(this.locationId, 'sbc')
            .pipe(take(1))
            .subscribe(equipments => {
                var keys = Object.keys(equipments);
                keys.forEach(key => {
                    this.realtimeService.requestHistoricDate(
                        equipments[key].value,
                        commandTypeID,
                        new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
                        new Date().toISOString(),
                        this.hourSetting
                    );
                });
            });
    }

    resetData() {
        this.lastUpdated = new Date();

        this.graphData = {
            amber: [],
            blue: [],
            green: [],
            multiEquipData: [],
            red: []
        };

        this.graphData4xx = {
            amber: [],
            blue: [],
            green: [],
            multiEquipData: [],
            red: []
        };

        this.graphData5xx = {
            amber: [],
            blue: [],
            green: [],
            multiEquipData: [],
            red: []
        };

        this.graphData6xx = {
            amber: [],
            blue: [],
            green: [],
            multiEquipData: [],
            red: []
        };

        this.anomalyData4xx = [];
        this.anomalyData5xx = [];
        this.anomalyData6xx = [];

        this.tempAiData4xx = [];
        this.tempAiData5xx = [];
        this.tempAiData6xx = [];

        this.originalData = [];
        this.summaryData = [];
        this.messageData = [];
        this.documentTimeStamps = [];
        this._chartDataUpdated.next(null);
    }

    dispose() {}

    private checkDataIfProcessed(id: string, timestamp: string) {
        const index = this.documentTimeStamps.findIndex(document => {
            return document.id === id && document.timestamp === timestamp;
        });

        if (index === -1) {
            this.documentTimeStamps.push({ id: id, timestamp: timestamp });
            return false;
        }

        return true;
    }

    public processRTGDataArray(dataArray: any[]) {
        if (dataArray) {
            dataArray.forEach(dataRow => {
                if (dataRow) {
                    if (!this.checkDataIfProcessed(dataRow.id, dataRow.timestamp)) {
                        this.processData(dataRow);
                    }
                }
            });
        }
    }

    public processAnomalyPointsDataArray(
        dataArray: any[],
        storageArray: AzureAiLastDetect[],
        graphData: SipGraphData,
        commandTypeID: string
    ) {
        if (dataArray) {
            dataArray.forEach(dataRow => {
                if (dataRow) {
                    if (!this.checkDataIfProcessed(dataRow.id, dataRow.timestamp)) {
                        this.processAnomalyPoints(dataRow.data, storageArray, graphData, commandTypeID);
                    }
                }
            });
        }
    }

    public processAnomalyPoints(
        data: any,
        storageArray: AzureAiLastDetect[],
        graphData: SipGraphData,
        commandTypeID: string
    ): void {
        const row = data[0];

        if (row !== null) {
            if (row.UTCDateTime !== null && row.UTCDateTime !== undefined) {
                this.loadingAIData = true;

                if (this.checkDateTimeIsRecent(row.UTCDateTime)) {
                    var dateTime = this.getClientSideTime(row.UTCDateTime);
                    var timeIndex = storageArray.findIndex(item => item.UTCDateTime === dateTime);

                    if (timeIndex === -1 && !isNaN(dateTime.getTime()) && row.EquipmentId !== undefined) {
                        var document = {
                            ExpectedValue: Math.ceil(parseFloat(row.ExpectedValue)),
                            IsAnomaly: row.IsAnomaly,
                            IsNegativeAnomaly: row.IsNegativeAnomaly,
                            IsPositiveAnomaly: row.IsPositiveAnomaly,
                            LowerMargin: Math.ceil(parseFloat(row.LowerMargin)),
                            Period: Math.ceil(parseFloat(row.Period)),
                            SuggestedWindow: Math.ceil(parseFloat(row.SuggestedWindow)),
                            UTCDateTime: dateTime,
                            EquipmentId: row.EquipmentId,
                            EquipmentName: row.EquipmentName,
                            ActualCount: parseInt(row.ActualCount),
                            UpperMargin: Math.ceil(parseFloat(row.UpperMargin))
                        };

                        storageArray.push(document);

                        var sameHourIndex = storageArray.findIndex(
                            item =>
                                item.UTCDateTime.getHours() === this.getClientSideTime(row.UTCDateTime).getHours() &&
                                item.UTCDateTime.getDay() === this.getClientSideTime(row.UTCDateTime).getDay() &&
                                item.EquipmentId === row.EquipmentId.toString()
                        );

                        var findLinkedEquipment = graphData.multiEquipData.findIndex(
                            item => item.equipId === storageArray[sameHourIndex].EquipmentId
                        );

                        // equip id should be used to index and push into correct part of the array.
                        if (findLinkedEquipment === -1) {
                            graphData.multiEquipData.push({
                                equipId: storageArray[sameHourIndex].EquipmentId, // would be guid
                                equipmentName: storageArray[sameHourIndex].EquipmentName,
                                aiData: {
                                    aiDetected: [],
                                    nonAiDetected: [],
                                    upperMargin: [],
                                    lowerMargin: []
                                } as AIDataPoint
                            });
                            findLinkedEquipment = graphData.multiEquipData.length - 1;
                        }

                        var isAnomaly =
                            sameHourIndex !== -1
                                ? JSON.parse(storageArray[sameHourIndex].IsAnomaly.toLowerCase())
                                : false;

                        graphData.multiEquipData[findLinkedEquipment].equipId = storageArray[sameHourIndex].EquipmentId;
                        graphData.multiEquipData[findLinkedEquipment].equipmentName =
                            storageArray[sameHourIndex].EquipmentName;

                        if (storageArray[sameHourIndex] !== undefined) {
                            graphData.multiEquipData[findLinkedEquipment].aiData.aiDetected.push({
                                x: storageArray[sameHourIndex].UTCDateTime,
                                y: storageArray[sameHourIndex].ActualCount,
                                aiDataPoint: storageArray[sameHourIndex]
                            });
                            graphData.multiEquipData[findLinkedEquipment].aiData.nonAiDetected.push({
                                x: storageArray[sameHourIndex].UTCDateTime,
                                y: isAnomaly ? null : storageArray[sameHourIndex].ActualCount,
                                aiDataPoint: storageArray[sameHourIndex]
                            });

                            graphData.multiEquipData[findLinkedEquipment].aiData.upperMargin.push({
                                x: storageArray[sameHourIndex].UTCDateTime,
                                y: storageArray[sameHourIndex].UpperMargin + storageArray[sameHourIndex].ExpectedValue,
                                aiDataPoint: storageArray[sameHourIndex]
                            });
                            graphData.multiEquipData[findLinkedEquipment].aiData.lowerMargin.push({
                                x: storageArray[sameHourIndex].UTCDateTime,
                                y: storageArray[sameHourIndex].ExpectedValue - storageArray[sameHourIndex].LowerMargin,
                                aiDataPoint: storageArray[sameHourIndex]
                            });
                        } else {
                            graphData.multiEquipData[findLinkedEquipment].aiData.aiDetected.push({
                                x: storageArray[sameHourIndex].UTCDateTime,
                                y: null,
                                aiDataPoint: storageArray[sameHourIndex]
                            });
                            graphData.multiEquipData[findLinkedEquipment].aiData.nonAiDetected.push({
                                x: storageArray[sameHourIndex].UTCDateTime,
                                y: null,
                                aiDataPoint: storageArray[sameHourIndex]
                            });
                        }

                        graphData.multiEquipData = [...graphData.multiEquipData];

                        if (graphData.multiEquipData[findLinkedEquipment].aiData.aiDetected.length > this.hourSetting) {
                            this.requestCleanAIData(commandTypeID);
                        }
                    }
                }

                this.ConstructGraphData();
            }
        }
    }

    private requestCleanAIData(commandTypeID: string): void {
        switch (commandTypeID) {
            case this.AZURE_AI_ANOMALY_LAST_DETECT_4XX_COMMAND_TYPE_ID:
                this.graphData4xx.multiEquipData = [];
                this.anomalyData4xx = [];
                this.ConstructGraphData();
                this.equipmentId !== this.locationId
                    ? this.historicRequestForSingleEquip(this.AZURE_AI_ANOMALY_LAST_DETECT_4XX_COMMAND_TYPE_ID)
                    : this.historicRequestForAllEquipment(this.AZURE_AI_ANOMALY_LAST_DETECT_4XX_COMMAND_TYPE_ID);
                break;
            case this.AZURE_AI_ANOMALY_LAST_DETECT_5XX_COMMAND_TYPE_ID:
                this.graphData5xx.multiEquipData = [];
                this.anomalyData5xx = [];
                this.ConstructGraphData();
                this.equipmentId !== this.locationId
                    ? this.historicRequestForSingleEquip(this.AZURE_AI_ANOMALY_LAST_DETECT_5XX_COMMAND_TYPE_ID)
                    : this.historicRequestForAllEquipment(this.AZURE_AI_ANOMALY_LAST_DETECT_5XX_COMMAND_TYPE_ID);
                break;
            case this.AZURE_AI_ANOMALY_LAST_DETECT_6XX_COMMAND_TYPE_ID:
                this.graphData6xx.multiEquipData = [];
                this.anomalyData6xx = [];
                this.ConstructGraphData();
                this.equipmentId !== this.locationId
                    ? this.historicRequestForSingleEquip(this.AZURE_AI_ANOMALY_LAST_DETECT_6XX_COMMAND_TYPE_ID)
                    : this.historicRequestForAllEquipment(this.AZURE_AI_ANOMALY_LAST_DETECT_6XX_COMMAND_TYPE_ID);
                break;
        }
    }

    private checkDateTimeIsRecent(dateTime: any): boolean {
        var OneDayAgo = new Date().getUTCMilliseconds() - 24 * 60 * 60 * 1000; /// hour/min/sec/ms = 1 day from now
        if (OneDayAgo > new Date(dateTime).getUTCMilliseconds()) {
            return false;
        } else {
            return true;
        }
    }

    private getClientSideTime(dateTime: any): Date {
        var dateTimeUTC = new Date(dateTime);
        return new Date(dateTimeUTC.setHours(dateTimeUTC.getHours() - this.getTimeShift(dateTimeUTC) + 1)); // plus one because of rounding
    }

    private getTimeShift(date: Date): number {
        return date.getTimezoneOffset() / 60;
    }

    private ConstructGraphData(): void {
        this.tempAiData4xx = [];
        this.tempAiData5xx = [];
        this.tempAiData6xx = [];

        this.createTempData(this.graphData4xx, this.tempAiData4xx);
        this.createTempData(this.graphData5xx, this.tempAiData5xx);
        this.createTempData(this.graphData6xx, this.tempAiData6xx);

        this.sipDataUpdated.next(null);
    }

    private createTempData(graphData: SipGraphData, tempAiData: any[]): void {
        graphData.multiEquipData.forEach(item => {
            // process acordian stats for graph
            let countedAnomaly = 0;

            item.aiData.aiDetected.forEach(item => {
                if (item.aiDataPoint.IsAnomaly.toLocaleLowerCase() === 'true') {
                    countedAnomaly++;
                }
            });

            const data = {
                equipmentId: item.equipId,
                equipmentName: item.equipmentName,
                anomaliesDetected: countedAnomaly,
                expanded: true,
                chartData: [
                    {
                        labels: [],
                        datasets: [
                            {
                                label: 'Non-Anomalous',
                                backgroundColor: this.dashletService.getChartColors().green,
                                borderColor: this.dashletService.getChartColors().green,
                                pointBackgroundColor: this.dashletService.getChartColors().green,
                                pointHighlightStroke: this.dashletService.getChartColors().green,
                                pointRadius: 3.5,
                                pointHitRadius: 10,
                                fill: false,
                                borderWidth: 1,
                                data: item.aiData.nonAiDetected.sort((a, b) => a.x.getTime() - b.x.getTime())
                            },
                            {
                                label: 'Anomalous',
                                backgroundColor: this.dashletService.getChartColors().red,
                                borderColor: this.dashletService.getChartColors().red,
                                pointBackgroundColor: this.dashletService.getChartColors().red,
                                pointHighlightStroke: this.dashletService.getChartColors().red,
                                pointRadius: 3.5,
                                pointHitRadius: 10,
                                fill: false,
                                borderWidth: 1,
                                data: item.aiData.aiDetected.sort((a, b) => a.x.getTime() - b.x.getTime())
                            },
                            {
                                label: 'Upper Margin',
                                borderColor: this.dashletService.getChartColors().greyLight,
                                pointHighlightStroke: null,
                                pointRadius: null,
                                pointHitRadius: null,
                                fill: false,
                                borderWidth: 0.8,
                                borderDash: [5, 5],
                                backgroundColor: 'transparent',
                                pointBackgroundColor: 'transparent',
                                data: item.aiData.upperMargin.sort((a, b) => a.x.getTime() - b.x.getTime())
                            },
                            {
                                label: 'Lower Margin',
                                borderColor: this.dashletService.getChartColors().greyLight,
                                pointHighlightStroke: null,
                                pointRadius: null,
                                pointHitRadius: null,
                                fill: false,
                                borderWidth: 0.8,
                                borderDash: [7, 5],
                                backgroundColor: 'transparent',
                                pointBackgroundColor: 'transparent',
                                data: item.aiData.lowerMargin.sort((a, b) => a.x.getTime() - b.x.getTime())
                            }
                        ]
                    }
                ]
            };

            tempAiData.push(data);
        });
    }

    public processData(dataParam: any) {
        let mainArray: any[] = dataParam.data;
        let date = new Date(dataParam.timestamp);

        let green = 0;
        let blue = 0;
        let amber = 0;
        let red = 0;
        mainArray.forEach(response => {
            let hits = 0;
            this.sipRequestNames.forEach(name => {
                hits += parseInt(response[name]);
            });
            if ((response['Error Code'] as string).startsWith('1')) {
                blue += hits;
            } else if (
                (response['Error Code'] as string).startsWith('2') ||
                (response['Error Code'] as string).startsWith('3')
            ) {
                green += hits;
            } else if ((response['Error Code'] as string).startsWith('4')) {
                amber += hits;
            } else {
                red += hits;
            }
        });

        this.graphData.amber.sort((a, b) => (a.x > b.x ? 1 : -1));
        this.insertZeros(this.graphData.amber);
        this.graphData.amber.push({ x: date, y: Number(amber) });
        this.graphData.green.sort((a, b) => (a.x > b.x ? 1 : -1));
        this.insertZeros(this.graphData.green);
        this.graphData.green.push({ x: date, y: Number(green) });
        this.graphData.red.sort((a, b) => (a.x > b.x ? 1 : -1));
        this.insertZeros(this.graphData.red);
        this.graphData.red.push({ x: date, y: Number(red) });
        this.graphData.blue.sort((a, b) => (a.x > b.x ? 1 : -1));
        this.insertZeros(this.graphData.blue);
        this.graphData.blue.push({ x: date, y: Number(blue) });
        if (this.graphData.amber.length > this.hourSetting) {
            //Reached max length for arrays
            this.graphData.amber.splice(0, this.graphData.amber.length - this.hourSetting);
        }
        if (this.graphData.green.length > this.hourSetting) {
            this.graphData.green.splice(0, this.graphData.green.length - this.hourSetting);
        }
        if (this.graphData.red.length > this.hourSetting) {
            this.graphData.red.splice(0, this.graphData.red.length - this.hourSetting);
        }
        if (this.graphData.blue.length > this.hourSetting) {
            this.graphData.blue.splice(0, this.graphData.blue.length - this.hourSetting);
        }

        this.graphData.amber.sort((a, b) => (a.x > b.x ? 1 : -1));
        this.graphData.green.sort((a, b) => (a.x > b.x ? 1 : -1));
        this.graphData.red.sort((a, b) => (a.x > b.x ? 1 : -1));
        this.graphData.blue.sort((a, b) => (a.x > b.x ? 1 : -1));
        this.resetSummaryData();

        let informationSummary = this.summaryData.filter(s => s.response === '1xx')[0];
        let successSummary = this.summaryData.filter(s => s.response === 'Success')[0];
        let clientErrorSummary = this.summaryData.filter(s => s.response === '4xx')[0];
        let serverErrorSummary = this.summaryData.filter(s => s.response === '5xx')[0];

        let codeMap = new Map<string, number>();
        this.messageData = [];
        this.responsesByMessage = {
            ACK: [],
            BYE: [],
            CANCEL: [],
            INFO: [],
            INVITE: [],
            MESSAGE: [],
            NOTIFY: [],
            OPTIONS: [],
            PRACK: [],
            PUBLISH: [],
            REFER: [],
            REGISTER: [],
            SUBSCRIBE: [],
            UPDATE: []
        };

        this.originalData.forEach(originalDataRow => {
            originalDataRow['data'].forEach(d => {
                let code: string = d['Error Code'];
                let responses = 0;

                this.sipRequestNames.forEach(r => {
                    responses += parseInt(d[r]);
                    //loading resposes by message type
                    let index = this.responsesByMessage[r]
                        .map(x => x.response)
                        .indexOf(this.getSIPReponseMessageFromCode(code));
                    if (parseInt(d[r]) !== 0) {
                        index === -1
                            ? this.responsesByMessage[r].push({
                                  response: this.getSIPReponseMessageFromCode(code),
                                  count: parseInt(d[r])
                              })
                            : (this.responsesByMessage[r][index].count += parseInt(d[r]));
                    }
                });
                if (codeMap.has(code)) {
                    let previousResponses = codeMap.get(code) + responses;
                    codeMap.set(code, previousResponses);
                } else {
                    codeMap.set(code, responses);
                }
            });
        });

        informationSummary.sessions = Array.from(codeMap)
            .filter(x => x[1] !== 0 && x[0].startsWith('1'))
            .reduce((acc, responseCode) => acc + responseCode[1], 0);

        successSummary.sessions = Array.from(codeMap)
            .filter(c => c[1] !== 0 && (c[0].startsWith('2') || c[0].startsWith('3')))
            .reduce((acc, responseCode) => acc + responseCode[1], 0);

        clientErrorSummary.sessions = Array.from(codeMap)
            .filter(c => c[1] !== 0 && c[0].startsWith('4'))
            .reduce((acc, responseCode) => acc + responseCode[1], 0);

        serverErrorSummary.sessions = Array.from(codeMap)
            .filter(c => c[1] !== 0 && c[0].startsWith('5'))
            .reduce((acc, responseCode) => acc + responseCode[1], 0);

        const totalResponses = Array.from(codeMap)
            .filter(x => x[1] !== 0)
            .reduce((acc, responseCode) => acc + responseCode[1], 0);

        this.sipRequestNames.forEach(r => {
            if (this.responsesByMessage[r].length) {
                let total = this.responsesByMessage[r].reduce((accumulator, message) => {
                    return accumulator + message.count;
                }, 0);
                let message: SipMessageData = {
                    message: r,
                    total: total,
                    percentage: Math.round((total / totalResponses) * 100) || 0
                };

                this.messageData.push({
                    message: message.message,
                    total: message.total,
                    percentage: message.percentage,
                    distribution: this.getDistribution(message),
                    expansion: this.getMessageRowExpansionData(message)
                });
            }
        });
        //Calculate Distribution for informational codes
        const infoMax = Array.from(codeMap)
            .filter(c => c[0].startsWith('1'))
            .reduce(function (prev, current) {
                return prev[1] > current[1] ? prev : current;
            });
        if (infoMax) {
            const infoSecondMax = Array.from(codeMap)
                .filter(c => c[0].startsWith('1') && c[0] !== infoMax[0])
                .reduce(function (prev, current) {
                    return prev[1] > current[1] ? prev : current;
                });
            informationSummary.distribution.first.title = this.getSIPReponseMessageFromCode(infoMax[0]);
            informationSummary.distribution.first.value = infoMax[1];
            informationSummary.distribution.second.title = infoSecondMax
                ? this.getSIPReponseMessageFromCode(infoSecondMax[0])
                : '';
            informationSummary.distribution.second.value = infoSecondMax ? infoSecondMax[1] : 0;

            let otherValue = Array.from(codeMap)
                .filter(
                    c => c[0].startsWith('1') && c[0] !== infoMax[0] && c[0] !== (infoSecondMax ? infoSecondMax[0] : '')
                )
                .map(x => x[1])
                .reduce(function (total, current) {
                    return total + current;
                });
            informationSummary.distribution.other.title = 'Other';
            informationSummary.distribution.other.value = otherValue;
        } else {
            informationSummary.distribution.first.title = '';
            informationSummary.distribution.first.value = 0;
            informationSummary.distribution.second.title = '';
            informationSummary.distribution.second.value = 0;
            informationSummary.distribution.other.title = '';
            informationSummary.distribution.other.value = 0;
        }

        let infoTotal =
            informationSummary.distribution.first.value +
            informationSummary.distribution.second.value +
            informationSummary.distribution.other.value;
        informationSummary.distribution.first.value =
            (informationSummary.distribution.first.value / infoTotal) * 100 || 0;
        informationSummary.distribution.second.value =
            (informationSummary.distribution.second.value / infoTotal) * 100 || 0;
        informationSummary.distribution.other.value =
            (informationSummary.distribution.other.value / infoTotal) * 100 || 0;
        //Calculate Distribution for success codes
        const successMax = Array.from(codeMap)
            .filter(c => c[0].startsWith('2') || c[0].startsWith('3'))
            .reduce(function (prev, current) {
                return prev[1] > current[1] ? prev : current;
            });
        if (successMax) {
            const successSecondMax = Array.from(codeMap)
                .filter(c => (c[0].startsWith('2') || c[0].startsWith('3')) && c[0] !== successMax[0])
                .reduce(function (prev, current) {
                    return prev[1] > current[1] ? prev : current;
                });
            successSummary.distribution.first.title = this.getSIPReponseMessageFromCode(successMax[0]);
            successSummary.distribution.first.value = successMax[1];
            successSummary.distribution.second.title = successSecondMax
                ? this.getSIPReponseMessageFromCode(successSecondMax[0])
                : '';
            successSummary.distribution.second.value = successSecondMax ? successSecondMax[1] : 0;
            let otherValue = Array.from(codeMap)
                .filter(
                    c =>
                        (c[0].startsWith('2') || c[0].startsWith('3')) &&
                        c[0] !== successMax[0] &&
                        c[0] !== (successSecondMax ? successSecondMax[0] : '')
                )
                .map(x => x[1])
                .reduce(function (total, current) {
                    return total + current;
                });
            successSummary.distribution.other.title = 'Other';
            successSummary.distribution.other.value = otherValue;
        } else {
            successSummary.distribution.first.title = '';
            successSummary.distribution.first.value = 0;
            successSummary.distribution.second.title = '';
            successSummary.distribution.second.value = 0;
            successSummary.distribution.other.title = '';
            successSummary.distribution.other.value = 0;
        }

        let successTotal =
            successSummary.distribution.first.value +
            successSummary.distribution.second.value +
            successSummary.distribution.other.value;
        successSummary.distribution.first.value = (successSummary.distribution.first.value / successTotal) * 100 || 0;
        successSummary.distribution.second.value = (successSummary.distribution.second.value / successTotal) * 100 || 0;
        successSummary.distribution.other.value = (successSummary.distribution.other.value / successTotal) * 100 || 0;

        //Calculate Distribution for client codes
        const clientMax = Array.from(codeMap)
            .filter(c => c[0].startsWith('4'))
            .reduce(function (prev, current) {
                return prev[1] > current[1] ? prev : current;
            });
        if (clientMax) {
            const clientSecondMax = Array.from(codeMap)
                .filter(c => c[0].startsWith('4') && c[0] !== clientMax[0])
                .reduce(function (prev, current) {
                    return prev[1] > current[1] ? prev : current;
                });
            clientErrorSummary.distribution.first.title = this.getSIPReponseMessageFromCode(clientMax[0]);
            clientErrorSummary.distribution.first.value = clientMax[1];
            clientErrorSummary.distribution.second.title = clientSecondMax
                ? this.getSIPReponseMessageFromCode(clientSecondMax[0])
                : '';
            clientErrorSummary.distribution.second.value = clientSecondMax ? clientSecondMax[1] : 0;

            let otherValue = Array.from(codeMap)
                .filter(
                    c =>
                        c[0].startsWith('4') &&
                        c[0] !== clientMax[0] &&
                        c[0] !== (clientSecondMax ? clientSecondMax[0] : '')
                )
                .map(x => x[1])
                .reduce(function (total, current) {
                    return total + current;
                });
            clientErrorSummary.distribution.other.title = 'Other';
            clientErrorSummary.distribution.other.value = otherValue;
        } else {
            clientErrorSummary.distribution.first.title = '';
            clientErrorSummary.distribution.first.value = 0;
            clientErrorSummary.distribution.second.title = '';
            clientErrorSummary.distribution.second.value = 0;
            clientErrorSummary.distribution.other.title = '';
            clientErrorSummary.distribution.other.value = 0;
        }

        let clientTotal =
            clientErrorSummary.distribution.first.value +
            clientErrorSummary.distribution.second.value +
            clientErrorSummary.distribution.other.value;
        clientErrorSummary.distribution.first.value =
            (clientErrorSummary.distribution.first.value / clientTotal) * 100 || 0;
        clientErrorSummary.distribution.second.value =
            (clientErrorSummary.distribution.second.value / clientTotal) * 100 || 0;
        clientErrorSummary.distribution.other.value =
            (clientErrorSummary.distribution.other.value / clientTotal) * 100 || 0;
        this._chartDataUpdated.next(null);

        //Calculate Distribution for serverr codes
        const serverMax = Array.from(codeMap)
            .filter(c => c[0].startsWith('5'))
            .reduce(function (prev, current) {
                return prev[1] > current[1] ? prev : current;
            });
        if (serverMax) {
            const serverSecondMax = Array.from(codeMap)
                .filter(c => c[0].startsWith('5') && c[0] !== serverMax[0])
                .reduce(function (prev, current) {
                    return prev[1] > current[1] ? prev : current;
                });
            serverErrorSummary.distribution.first.title = this.getSIPReponseMessageFromCode(serverMax[0]);
            serverErrorSummary.distribution.first.value = serverMax[1];
            serverErrorSummary.distribution.second.title = serverSecondMax
                ? this.getSIPReponseMessageFromCode(serverSecondMax[0])
                : '';
            serverErrorSummary.distribution.second.value = serverSecondMax ? serverSecondMax[1] : 0;

            let otherValue = Array.from(codeMap)
                .filter(
                    c =>
                        c[0].startsWith('5') &&
                        c[0] !== serverMax[0] &&
                        c[0] !== (serverSecondMax ? serverSecondMax[0] : '')
                )
                .map(x => x[1])
                .reduce(function (total, current) {
                    return total + current;
                });
            serverErrorSummary.distribution.other.title = 'Other';
            serverErrorSummary.distribution.other.value = otherValue;
        } else {
            serverErrorSummary.distribution.first.title = '';
            serverErrorSummary.distribution.first.value = 0;
            serverErrorSummary.distribution.second.title = '';
            serverErrorSummary.distribution.second.value = 0;
            serverErrorSummary.distribution.other.title = '';
            serverErrorSummary.distribution.other.value = 0;
        }

        let serverTotal =
            serverErrorSummary.distribution.first.value +
            serverErrorSummary.distribution.second.value +
            serverErrorSummary.distribution.other.value;
        serverErrorSummary.distribution.first.value =
            (serverErrorSummary.distribution.first.value / serverTotal) * 100 || 0;
        serverErrorSummary.distribution.second.value =
            (serverErrorSummary.distribution.second.value / serverTotal) * 100 || 0;
        serverErrorSummary.distribution.other.value =
            (serverErrorSummary.distribution.other.value / serverTotal) * 100 || 0;
        informationSummary.percentage =
            Math.round(
                (informationSummary.sessions /
                    (successSummary.sessions +
                        clientErrorSummary.sessions +
                        serverErrorSummary.sessions +
                        informationSummary.sessions)) *
                    100
            ) || 0;
        successSummary.percentage =
            Math.round(
                (successSummary.sessions /
                    (successSummary.sessions +
                        clientErrorSummary.sessions +
                        serverErrorSummary.sessions +
                        informationSummary.sessions)) *
                    100
            ) || 0;
        clientErrorSummary.percentage =
            Math.round(
                (clientErrorSummary.sessions /
                    (successSummary.sessions +
                        clientErrorSummary.sessions +
                        serverErrorSummary.sessions +
                        informationSummary.sessions)) *
                    100
            ) || 0;
        serverErrorSummary.percentage =
            Math.round(
                (serverErrorSummary.sessions /
                    (successSummary.sessions +
                        clientErrorSummary.sessions +
                        serverErrorSummary.sessions +
                        informationSummary.sessions)) *
                    100
            ) || 0;
        this._chartDataUpdated.next(null);

        //calculating subrow data
        //calculate infomation resposnes
        const informationResponses = Array.from(codeMap).filter(x => x[0].startsWith('1') && x[1] !== 0);
        const informationTotalResponse = informationResponses.reduce((acc, responseCode) => acc + responseCode[1], 0);
        const informationExpansion = informationResponses.map(i => {
            return {
                response: this.getSIPReponseMessageFromCode(i[0]),
                sessions: i[1],
                percentage: Math.round((i[1] / informationTotalResponse) * 100) || 0
            };
        });

        //calculate success responses
        const successResponses = Array.from(codeMap).filter(
            x => (x[0].startsWith('2') || x[0].startsWith('3')) && x[1] !== 0
        );
        const successTotalResponse = successResponses.reduce((acc, responseCode) => acc + responseCode[1], 0);
        const successExpansion = successResponses.map(i => {
            return {
                response: this.getSIPReponseMessageFromCode(i[0]),
                sessions: i[1],
                percentage: Math.round((i[1] / successTotalResponse) * 100) || 0
            };
        });
        //calculate client responses
        const clientResponses = Array.from(codeMap).filter(x => x[0].startsWith('4') && x[1] !== 0);
        const clientTotalResponse = clientResponses.reduce((acc, responseCode) => acc + responseCode[1], 0);
        const clientExpansion = clientResponses.map(i => {
            return {
                response: this.getSIPReponseMessageFromCode(i[0]),
                sessions: i[1],
                percentage: Math.round((i[1] / clientTotalResponse) * 100) || 0
            };
        });
        //calculate server responses

        const serverResponses = Array.from(codeMap).filter(x => x[0].startsWith('5') && x[1] !== 0);
        const serverTotalResponse = serverResponses.reduce((acc, responseCode) => acc + responseCode[1], 0);
        const serverExpansion = serverResponses.map(i => {
            return {
                response: this.getSIPReponseMessageFromCode(i[0]),
                sessions: i[1],
                percentage: Math.round((i[1] / serverTotalResponse) * 100) || 0
            };
        });

        informationSummary.expansion = informationExpansion;
        successSummary.expansion = successExpansion;
        clientErrorSummary.expansion = clientExpansion;
        serverErrorSummary.expansion = serverExpansion;

        if (this.count === this.hourSetting) {
            this.sipDataUpdated.next(null);
            this.count = 1;
        } else {
            this.sipDataUpdated.next(null);
            this.count++;
        }
    }

    private insertZeros(data: { x: Date; y: number }[]) {
        if (data.length > 0) {
            const date: Date = data[0].x;
            const previousDate: Date = data[data.length - 1].x;
            while (Math.round(date.getTime() - previousDate.getTime()) > 1) {
                previousDate.setHours(previousDate.getHours() + 1);
                data.push({ x: previousDate, y: 0 });
            }
        }
    }

    private resetSummaryData() {
        this.summaryData = [
            {
                response: '1xx',
                percentage: 0,
                sessions: 0,
                distribution: {
                    first: { title: '', value: 0, colour: this.dashletService.getChartColors().blue },
                    second: { title: '', value: 0, colour: '#5580FF' },
                    other: { title: '', value: 0, colour: '#9EB6FF' }
                },
                expansion: []
            },
            {
                response: 'Success',
                percentage: 0,
                sessions: 0,
                distribution: {
                    first: { title: '', value: 0, colour: this.dashletService.getChartColors().green },
                    second: { title: '', value: 0, colour: '#4DDC90' },
                    other: { title: '', value: 0, colour: '#99EBBF' }
                },
                expansion: []
            },
            {
                response: '4xx',
                percentage: 0,
                sessions: 0,
                distribution: {
                    first: { title: '', value: 0, colour: this.dashletService.getChartColors().amber },
                    second: { title: '', value: 0, colour: '#FFD555' },
                    other: { title: '', value: 0, colour: '#FFE79E' }
                },
                expansion: []
            },
            {
                response: '5xx',
                percentage: 0,
                sessions: 0,
                distribution: {
                    first: { title: '', value: 0, colour: this.dashletService.getChartColors().red },
                    second: { title: '', value: 0, colour: '#FF557F' },
                    other: { title: '', value: 0, colour: '#FF9EB5' }
                },
                expansion: []
            }
        ];
    }

    public changeObservedMessages(messages: string[]) {
        this.sipDataUpdating.next(null);
        this.sipRequestNames = [];
        if (messages) {
            messages.forEach(message => {
                if (message === 'Registration') {
                    this.sipRequestNames.push(...['REGISTER']);
                } else if (message === 'Invites') {
                    this.sipRequestNames.push(...['INVITE', 'ACK', 'PRACK', 'CANCEL']);
                } else if (message === 'Service Messages') {
                    this.sipRequestNames.push(...['PUBLISH', 'NOTIFY', 'SUBSCRIBE']);
                } else if (message === 'Informational') {
                    this.sipRequestNames.push(...['OPTIONS', 'INFO']);
                } else if (message === 'Other') {
                    this.sipRequestNames.push(...['MESSAGE', 'REFER', 'UPDATE']);
                } else if (message === 'Bye') {
                    this.sipRequestNames.push(...['BYE']);
                }
            });
        }

        this.graphData.amber = [];
        this.graphData.green = [];
        this.graphData.red = [];
        this.graphData.blue = [];

        this.originalData.map((currentElement, index) => {
            this.processData(currentElement);
        });
    }
    //#endregion

    private getMessageRowExpansionData(row): SipSummaryData[] {
        return this.responsesByMessage[row['message']].map(r => {
            return {
                response: r.response,
                sessions: r.count,
                percentage: Math.round((r.count / row['total']) * 100) || 0
            };
        });
    }

    getDistribution(row: SipMessageData) {
        let distributions = [];
        let distributionData = [
            {
                response: '1',
                distribution: {
                    first: { title: '', value: 0, colour: this.dashletService.getChartColors().blue },
                    second: { title: '', value: 0, colour: '#5580FF' },
                    other: { title: '', value: 0, colour: '#9EB6FF' }
                }
            },
            {
                response: 'Success',
                distribution: {
                    first: { title: '', value: 0, colour: this.dashletService.getChartColors().green },
                    second: { title: '', value: 0, colour: '#4DDC90' },
                    other: { title: '', value: 0, colour: '#99EBBF' }
                }
            },
            {
                response: '4',
                distribution: {
                    first: { title: '', value: 0, colour: this.dashletService.getChartColors().amber },
                    second: { title: '', value: 0, colour: '#FFD555' },
                    other: { title: '', value: 0, colour: '#FFE79E' }
                }
            },
            {
                response: '5',
                distribution: {
                    first: { title: '', value: 0, colour: this.dashletService.getChartColors().red },
                    second: { title: '', value: 0, colour: '#FF557F' },
                    other: { title: '', value: 0, colour: '#FF9EB5' }
                }
            }
        ];

        distributionData.forEach(data => {
            let responses = this.responsesByMessage[row['message']].filter(c =>
                data['response'] === 'Success'
                    ? c['response'].startsWith('2') || c['response'].startsWith('3')
                    : c['response'].startsWith(data['response'])
            );
            let maxResponse =
                responses.length > 0
                    ? responses.reduce(function (prev, current) {
                          return prev['count'] > current['count'] ? prev : current;
                      })
                    : undefined;

            if (maxResponse) {
                distributions.push({
                    value: (maxResponse['count'] / row['total']) * 100,
                    title: this.getSIPReponseMessageFromCode(maxResponse['response']),
                    colour: data.distribution.first.colour
                });

                responses = this.responsesByMessage[row['message']].filter(
                    c =>
                        (data['response'] === 'Success'
                            ? c['response'].startsWith('2') || c['response'].startsWith('3')
                            : c['response'].startsWith(data['response'])) && c['response'] !== maxResponse['response']
                );

                let secondMaxResponse =
                    responses.length > 0
                        ? responses.reduce(function (prev, current) {
                              return prev['count'] > current['count'] ? prev : current;
                          })
                        : undefined;

                if (secondMaxResponse && responses.length > 0) {
                    distributions.push({
                        value: (secondMaxResponse['count'] / row['total']) * 100,
                        title: this.getSIPReponseMessageFromCode(secondMaxResponse['response']),
                        colour: data.distribution.second.colour
                    });

                    responses = this.responsesByMessage[row['message']]
                        .filter(
                            c =>
                                (data['response'] === 'Success'
                                    ? c['response'].startsWith('2') || c['response'].startsWith('3')
                                    : c['response'].startsWith(data['response'])) &&
                                !maxResponse['response'].startsWith(c['response']) &&
                                !secondMaxResponse['response'].startsWith(c['response']) &&
                                c['response'] !== (secondMaxResponse ? maxResponse['response'] : '')
                        )
                        .map(x => x['count']);

                    let otherValue =
                        responses.length > 0
                            ? responses.reduce(function (total, current) {
                                  return total + current;
                              })
                            : undefined;

                    if (otherValue) {
                        distributions.push({
                            value: (otherValue / row['total']) * 100,
                            title: 'Other',
                            colour: data.distribution.other.colour
                        });
                    }
                }
            }
        });
        return distributions;
    }

    getSIPReponseMessageFromCode(response: string) {
        switch (response) {
            case '100':
                return '100: Trying';
            case '180':
                return '180: Ringing';
            case '181':
                return '181: Call Being Forwarded';
            case '182':
                return '182: Queued';
            case '183':
                return '183: Session Progress';
            case '199':
                return '199: Early Dialog Terminated';
            case '200':
                return '200: OK';
            case '201':
                return '201: Created';
            case '202':
                return '202: Accepted';
            case '204':
                return '204: No Notification';
            case '300':
                return '300: Multiple Choices';
            case '301':
                return '301: Moved Permanently';
            case '302':
                return '302: Moved Temporarily';
            case '305':
                return '305: Use Proxy';
            case '380':
                return '380: Alternative Service';
            case '400':
                return '400: Bad Request';
            case '401':
                return '401: Unauthorized';
            case '402':
                return '402: Payment Required';
            case '403':
                return '403: Forbidden';
            case '404':
                return '404: Not Found';
            case '405':
                return '405: Method Not Allowed';
            case '406':
                return '406: Not Acceptable';
            case '407':
                return '407: Proxy Authentication Required';
            case '408':
                return '408: Request Timeout';
            case '409':
                return '409: Conflict';
            case '410':
                return '410: Gone';
            case '411':
                return '411: Length Required';
            case '412':
                return '412: Conditional Request Failed';
            case '413':
                return '413: Request Entity Too Large';
            case '414':
                return '414: URI Too Long';
            case '415':
                return '415: Unsupported Media Type';
            case '416':
                return '416: Unsupported URI Scheme';
            case '417':
                return '417: Unknown Resource-Priority';
            case '420':
                return '420: Bad Extension';
            case '421':
                return '421: Extension Required';
            case '422':
                return '422: Session Interval Too Small';
            case '423':
                return '423: Interval Too Brief';
            case '424':
                return '424: Bad Location Information';
            case '425':
                return '425: Bad Alert Message';
            case '428':
                return '428: Use Identity Header';
            case '429':
                return '429: Provide Referrer Identity';
            case '430':
                return '430: Flow Failed';
            case '433':
                return '433: Anonymity Disallowed';
            case '436':
                return '436: Bad Identity-Info';
            case '437':
                return '437: Unsupported Certificate';
            case '438':
                return '438: Invalid Identity Header';
            case '439':
                return '439: First Hop Lacks Outbound Support';
            case '440':
                return '440: Max-Breadth Exceeded';
            case '451':
                return '451: Unavailable For Legal Reasons';
            case '469':
                return '469: Bad Info Package';
            case '470':
                return '470: Consent Needed';
            case '480':
                return '480: Temporarily Unavailable';
            case '481':
                return '481: Call/Transaction Does Not Exist';
            case '482':
                return '482: Loop Detected';
            case '483':
                return '483: Too Many Hops';
            case '484':
                return '484: Address Incomplete';
            case '485':
                return '485: Ambiguous';
            case '486':
                return '486: Busy Here';
            case '487':
                return '487: Request Terminated';
            case '488':
                return '488: Not Acceptable Here';
            case '489':
                return '489: Bad Event';
            case '491':
                return '491: Request Pending';
            case '493':
                return '493: Undecipherable';
            case '494':
                return '494: Security Agreement Required';
            case '500':
                return '500: Internal Server Error';
            case '501':
                return '501: Not Implemented';
            case '502':
                return '502: Bad Gateway';
            case '503':
                return '503: Service Unavailable';
            case '504':
                return '504: Server Time-Out';
            case '505':
                return '505: HTTP Version Not Supported';
            case '513':
                return '513: Message Too Large';
            case '555':
                return '555: Push Notification Service Not Supported';
            case '580':
                return '580: Precondition Faliure';
            case '600':
                return '600: Busy Everywhere';
            case '603':
                return '603: Decline';
            case '604':
                return '604: Does Not Exist Anywhere';
            case '606':
                return '606: Not Acceptable';
            case '607':
                return '607: Unwanted';
            case '608':
                return '608: Rejected';
            default:
                return response;
        }
    }
}
