import {
    Customer,
    Dashlet,
    Equipment,
    Location,
    NetworkConnectivityData,
    RibbonCallCounter,
    RibbonCallCounterDrillDown,
    RibbonDSP,
    RibbonEquipment,
    RibbonInterface
} from '@models/index';
import { Subject } from 'rxjs';

enum AdminState {
    Disabled = 0,
    Enable = 1,
    Drained = 2
}

enum OperationalState {
    Up = 0,
    Down = 1,
    UpDraining = 2,
    UpDrained = 3,
    UpPeersDown = 4,
    NONE_EXISTS = 5
}

export class DashletRibbonSBCEdge extends Dashlet {
    public readonly COMMAND_TYPE_ID_UPTIME_LOGGED_IN_USERS = '0CD94CC2-42F3-4C6C-9F8B-E9CAF2995261';
    public readonly COMMAND_TYPE_ID_PROCESSOR_MEMORY = '9DD4FBF9-A1BF-45CD-B834-CA5EB551CAAC';
    public readonly COMMAND_TYPE_ID_SIGNALLING_CONFIGURED = 'A3D2EA7E-5590-4C77-8825-C20AC2A6840B';
    public readonly COMMAND_TYPE_ID_SIGNALLING_STATE = 'B693C203-1CF0-4A77-81E5-55B26C36ACCB';
    public readonly COMMAND_TYPE_ID_NETWORK_INTERFACE_STATUS = '63949497-FB25-4E25-9677-B1EABA5C2EA7';

    public readonly COMMAND_TYPE_ID_LICENCE = '587E9C6A-0642-49AF-BC54-DFC7C9DA56D4';
    public readonly COMMAND_TYPE_ID_CALL_COUNTER = 'FAA14B92-E63E-4845-BDE0-D7CD96F9268E';
    public readonly COMMAND_TYPE_ID_SYSTEM_INFO = '27143B65-2771-4FE0-AE9B-B3DE07FCAE91';
    public readonly COMMAND_TYPE_ID_DSP_INFO = '35C5E091-6CF8-443D-A35E-D4FB9135CCEB';

    private chartDataUpdated: Subject<null> = new Subject();
    get onChartDataUpdated() {
        return this.chartDataUpdated;
    }

    customer!: Customer;
    public equipment!: RibbonEquipment;
    public equipmentConnectivity!: Equipment;
    public location!: Location;
    readonly interval = 30;
    networkData!: any;

    public ribbonLicence = {
        AD: '-',
        ADExpirationDate: '-',
        AMRW: '-',
        AmrwExpirationDate: '-',
        AvailableDSPCh: '-',
        AvailableEnhancedMediaSessionswithTranscodingCh: '-',
        AvailableEnhancedMediaSessionswithoutTranscoding: '-',
        AvailableProxyLocalSrtp: '-',
        AvailableSILKCh: '-',
        AvailableSIPCh: '-',
        AvailableSIPRecrding: '-',
        AvailableSIPReg: '-',
        DSPChannels: '-',
        DSPExpirationDate: '-',
        EnhancedMediaSessionswithTranscodingChannels: '-',
        EnhancedMediaSessionswithTranscodingExpirationDate: '-',
        EnhancedMediaSessionswithoutTranscoding: '-',
        EnhancedMediaSessionswithoutTranscodingExpirationDate: '-',
        ExpirationDate: '-',
        ForkExpirationDate: '-',
        Forking: '-',
        LicenseType: '-',
        ProxyLocalSrtp: '-',
        ProxyLocalSrtpExpirationDate: '-',
        SILKChannels: '-',
        SIPChannels: '-',
        SIPREC: '-',
        SIPRECExpirationDate: '-',
        SIPRegistrations: '-',
        SipcExpirationDate: '-',
        SiprExpirationDate: '-',
        VideoPassthru: '-',
        VidpExpirationDate: '-'
    };

    public networkInterface: RibbonInterface[] = [];
    public dspList: RibbonDSP[] = [];

    public sipCallCounters: RibbonCallCounter = {
        CallAttempts: '0',
        CallSucceeded: '0',
        CallFailed: '0',
        CallCurrentlyUp: '0',
        CallCurrentlyTransient: '0',
        InternalGlares: '0',
        ExternalGlares: '0',
        CallAbandonedNoTrunk: '0',
        CallUnAnswered: '0'
    };

    public sipCallCountersDrilldown: RibbonCallCounterDrillDown[] = [];

    public signallingStates: {
        id: number;
        AdminState: string;
        OperationalString: string;
        C2TToFromDialMSTeams: string;
        FederatedFQDN: string;
        AvailableChannels: string;
        CurrentCalls: string;
        NumberOfChannels: string;
        CallRoutingTable: string;
        SIPServerTable: string;
        MediaList: string;
    }[] = [];

    public adminStateEnabledCount: any;
    public operationalStateDownCount: any;

    public networkConnectivity: NetworkConnectivityData;

    public networkConnectivityLoaded = false;
    public processorMemoryLoaded = false;
    public showSystemChart = false;
    public showNetworkGraph = false;
    public showNetworkInterface = true;
    public showProcessoryMemory = true;
    public showNetworkChart = true;
    public showSignallingGroups = false;
    public showLicenceOccupied = false;
    public showCallCounters = false;
    public showDSPSection = false;

    constructor() {
        super();
        this.sizes = [
            {
                id: 0,
                label: 'Standard',
                cols: 5,
                rows: 4
            }
        ];

        this.applySize(0);

        this.resetData();
    }

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);

        this.configured = v.customer && v.location && v.equipment;
        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
        } else {
            this.customer = new Customer('', '');
        }
        if (v.location) {
            this.location = new Location(v.location.value, v.location.label);
        } else {
            this.location = new Location('', '');
        }
        if (v.equipment) {
            this.equipmentConnectivity = new Equipment(v.equipment.value, v.equipment.label);
            this.equipment = {
                equipmentName: v.equipment.label,
                equipmentId: v.equipment.value,
                equipmentHost: v.equipment.host
            };
        } else {
            this.equipmentConnectivity = new Equipment('', '');
            this.equipment = {
                equipmentName: '',
                equipmentId: '',
                equipmentHost: ''
            };
        }

        this.showNetworkChart = v.showNetworkChart || false;
        this.showSignallingGroups = v.showSignallingGroups || false;
        this.showNetworkInterface = v.showNetworkInterface || false;
        this.showNetworkInterface = v.showNetworkInterface || false;
        this.showLicenceOccupied = v.showLicenceOccupied || false;
        this.showCallCounters = v.showCallCounters || false;
        this.showDSPSection = v.showDSPSection || false;

        //apply size
        let h = 0;
        if (this.showNetworkChart) h += 2.1;
        if (this.showSignallingGroups) h += 1.3;
        if (this.showNetworkInterface) h += 1.3;
        if (this.showLicenceOccupied) h += 1.3;
        if (this.showCallCounters) h += 2.2;
        if (this.showDSPSection) h += 1.2;
        this.applySizeExpansion(0, h);

        this.generatedNameTag = this.configured ? `${v.location.label} | ${v.equipment.label}` : 'Unconfigured';
        this.customNameTag = v.nameTag;
    }

    resetData() {
        this.networkInterface = [];
        this.signallingStates = [];
        this.adminStateEnabledCount = 0;
        this.operationalStateDownCount = 0;
        this.ribbonLicence = {
            AD: '-',
            ADExpirationDate: '-',
            AMRW: '-',
            AmrwExpirationDate: '-',
            AvailableDSPCh: '-',
            AvailableEnhancedMediaSessionswithTranscodingCh: '-',
            AvailableEnhancedMediaSessionswithoutTranscoding: '-',
            AvailableProxyLocalSrtp: '-',
            AvailableSILKCh: '-',
            AvailableSIPCh: '-',
            AvailableSIPRecrding: '-',
            AvailableSIPReg: '-',
            DSPChannels: '-',
            DSPExpirationDate: '-',
            EnhancedMediaSessionswithTranscodingChannels: '-',
            EnhancedMediaSessionswithTranscodingExpirationDate: '-',
            EnhancedMediaSessionswithoutTranscoding: '-',
            EnhancedMediaSessionswithoutTranscodingExpirationDate: '-',
            ExpirationDate: '-',
            ForkExpirationDate: '-',
            Forking: '-',
            LicenseType: '-',
            ProxyLocalSrtp: '-',
            ProxyLocalSrtpExpirationDate: '-',
            SILKChannels: '-',
            SIPChannels: '-',
            SIPREC: '-',
            SIPRECExpirationDate: '-',
            SIPRegistrations: '-',
            SipcExpirationDate: '-',
            SiprExpirationDate: '-',
            VideoPassthru: '-',
            VidpExpirationDate: '-'
        };
        this.dspList = [];
    }

    dispose() {}

    //#endregion

    //#region data processing

    processCallCountersData(data: any) {
        this.sipCallCounters = {
            CallAttempts: data.uxNumCallAttempts,
            CallSucceeded: data.uxNumCallSucceeded,
            CallFailed: data.uxNumCallFailed,
            CallCurrentlyUp: data.uxNumCallCurrentlyUp,
            CallCurrentlyTransient: data.uxNumCallCurrentlyTransient,
            InternalGlares: data.uxNumInternalGlares,
            ExternalGlares: data.uxNumExternalGlares,
            CallAbandonedNoTrunk: data.uxNumCallAbandonedNoTrunk,
            CallUnAnswered: data.uxNumCallUnAnswered
        };
    }

    processDspInfo(data: any) {
        let incomingDSP: RibbonDSP = {
            CardId: data.DSPCardPKId,
            Available: data.rt_Available,
            ServiceStatus: data.rt_ServiceStatus,
            CardType: data.rt_CardType,
            ChannelsInUse: data.rt_ChannelsInUse,
            CPUUsage: data.rt_CPUUsage,
            MohUpdate: data.rt_UpdatedTime,
            MohStatus: data.rt_MohFileStatus
        };

        let index = this.dspList.map(s => s.CardId).indexOf(incomingDSP.CardId);
        if (index !== -1) {
            this.dspList.splice(index, 1);
        }
        this.dspList.push(incomingDSP);
        this.dspList.sort((a, b) => (a.CardId > b.CardId ? 1 : b.CardId > a.CardId ? -1 : 0));
    }

    processLicenceData(data: any) {
        this.ribbonLicence = {
            AD: data.AD,
            ADExpirationDate: data.ADExpirationDate,
            AMRW: data.AMRW,
            AmrwExpirationDate: data.AmrwExpirationDate,
            AvailableDSPCh: data.AvailableDSPCh,
            AvailableEnhancedMediaSessionswithTranscodingCh: data.AvailableEnhancedMediaSessionswithTranscodingCh,
            AvailableEnhancedMediaSessionswithoutTranscoding: data.AvailableEnhancedMediaSessionswithoutTranscoding,
            AvailableProxyLocalSrtp: data.AvailableProxyLocalSrtp,
            AvailableSILKCh: data.AvailableSILKCh,
            AvailableSIPCh: data.AvailableSIPCh,
            AvailableSIPRecrding: data.AvailableSIPRecrding,
            AvailableSIPReg: data.AvailableSIPReg,
            DSPChannels: data.DSPChannels,
            DSPExpirationDate: data.DSPExpirationDate,
            EnhancedMediaSessionswithTranscodingChannels: data.EnhancedMediaSessionswithTranscodingChannels,
            EnhancedMediaSessionswithTranscodingExpirationDate: data.EnhancedMediaSessionswithTranscodingExpirationDate,
            EnhancedMediaSessionswithoutTranscoding: data.EnhancedMediaSessionswithoutTranscoding,
            EnhancedMediaSessionswithoutTranscodingExpirationDate:
                data.EnhancedMediaSessionswithoutTranscodingExpirationDate,
            ExpirationDate: data.ExpirationDate,
            ForkExpirationDate: data.ForkExpirationDate,
            Forking: data.Forking,
            LicenseType: data.LicenseType,
            ProxyLocalSrtp: data.ProxyLocalSrtp,
            ProxyLocalSrtpExpirationDate: data.ProxyLocalSrtpExpirationDate,
            SILKChannels: data.SILKChannels,
            SIPChannels: data.SIPChannels,
            SIPREC: data.SIPREC,
            SIPRECExpirationDate: data.SIPRECExpirationDate,
            SIPRegistrations: data.SIPRegistrations,
            SipcExpirationDate: data.SipcExpirationDate,
            SiprExpirationDate: data.SiprExpirationDate,
            VideoPassthru: data.VideoPassthru,
            VidpExpirationDate: data.VidpExpirationDate
        };
    }

    processSignallingState(data: any) {
        // Call counter drill down shares same ducments information - so using same processing method to do both

        // CALL COUNTER DRILL DOWN
        let callCounterInfo: RibbonCallCounterDrillDown = {
            id: data.SipsgPkId,
            C2TToFromDialMSTeams: data.Description,
            CurrentCalls: data.rt_NumCurrentCalls,
            TotalCalls: data.rt_NumTotalCalls,
            ConnectedCalls: data.rt_NumConnectedCalls,
            RefusedCalls: data.rt_NumRefusedCalls,
            ErroredCalls: data.rt_NumErroredCalls,
            BlockedCalls: data.rt_NumBlockedCalls,
            EgressCurrentCalls: data.rt_NumEgressCurrentCalls,
            EgressCallAttempts: data.rt_NumEgressCallAttempts,
            EgressAcceptedCalls: data.rt_NumEgressCallsAccepted,
            EgressCompletedCalls: data.rt_NumEgressCallsCompleted,
            EgressRejectedCalls: data.rt_NumEgressCallsRejected,
            EgressBlockedCalls: data.rt_NumEgressCallsBlocked,
            IngressCurrentCalls: data.rt_NumIngressCurrentCalls,
            IngressCallAttempts: data.rt_NumIngressCallAttempts,
            IngressAcceptedCalls: data.rt_NumIngressCallsAccepted,
            IngressCompletedCalls: data.rt_NumIngressCallsCompleted,
            IngressRejectedCalls: data.rt_NumIngressCallsRejected,
            IngressBlockedCalls: data.rt_NumIngressCallsBlocked
        };
        let index = this.sipCallCountersDrilldown.map(s => s.id).indexOf(callCounterInfo.id);
        if (index !== -1) {
            this.sipCallCountersDrilldown.splice(index, 1);
        }
        this.sipCallCountersDrilldown.push(callCounterInfo);
        this.sipCallCountersDrilldown.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));

        // SIGNALLING STATE
        let signallingState = {
            id: data.SipsgPkId,
            AdminState: AdminState[parseInt(data.customAdminState)].toString(),
            OperationalString: OperationalState[parseInt(data.rt_Status)].toString(),
            FederatedFQDN: data.RemoteHosts,
            C2TToFromDialMSTeams: data.Description,
            AvailableChannels: '',
            CurrentCalls: data.rt_NumCurrentCalls,
            NumberOfChannels: data.rt_NumberOfChannels,
            CallRoutingTable: '',
            SIPServerTable: '',
            MediaList: ''
        };
        index = this.signallingStates.map(s => s.id).indexOf(signallingState.id);
        if (index !== -1) {
            this.signallingStates.splice(index, 1);
        }
        this.signallingStates.push(signallingState);
        this.signallingStates.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
        this.adminStateEnabledCount = this.signallingStates.reduce(
            (acc, state) => (state.AdminState === 'Enable' ? acc + 1 : acc + 0),
            0
        );
        this.operationalStateDownCount = this.signallingStates.reduce(
            (acc, state) =>
                state.OperationalString === 'Down' ||
                state.OperationalString === 'UpDraining' ||
                state.OperationalString === 'UpDrained' ||
                state.OperationalString === 'UpPeersDown'
                    ? acc + 1
                    : acc + 0,
            0
        );
    }

    processNetworkInterfaceData(data: any) {
        let interfaceData = {
            Name: data.ifName,
            OperatorStatus: data.rt_ifOperatorStatus,
            ConfigIEState: data.ConfigIEState,
            IPv4: data.rt_ifIPv4Address,
            HwAddress: data.rt_ifHwAddress,
            Speed: data.rt_ifSpeed,
            NetworkingMode: data.ifNetworkingMode,
            TimingMode: data.gigabitTimingMode,
            Duplex: data.rt_ifDuplexity
        };

        let index = this.networkInterface.map(s => s.Name).indexOf(interfaceData.Name);
        if (index !== -1) {
            this.networkInterface.splice(index, 1);
        }
        this.networkInterface.push(interfaceData);
        this.networkInterface.sort((a, b) => (a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0));
    }
}
