import { Subject } from 'rxjs';

import { Customer, Dashlet, Threshold } from '@models/index';

export class TrunkGroup {
    name!: string;
    number!: number;
    size!: number;
    active!: number;
    severity!: number;
    commandType!: string;
    thresholds: Threshold[] = [];
    type!: string;
}

export class DashletTrunkGroupList extends Dashlet {
    readonly commandTypeIdOutOfServiceThreshold = 'B0998099-FA3E-4DA0-BC22-162485E1C058';

    public readonly commandTypeIdIpoUtilization = '78ab641f-fbdd-497e-bd93-e2ecabb51831';
    public readonly commandTypeIdAcmUtilization = 'f91b4082-1e63-43d4-820f-21b316afd1f2';
    public readonly commandTypeIdAcmTrunkGroups = 'a8de1686-7f14-4391-ba45-26dfd5fb0370';
    public readonly commandTypeIdAepUtilization = 'C31BC130-BA99-4ECD-86F5-C93B8E255D5E';
    public equipmentId!: string;
    private trunkGroups!: number[];
    public customer!: Customer;

    private ipoTrunks!: TrunkGroup[];
    private acmTrunks!: TrunkGroup[];
    trunks: TrunkGroup[] = [];

    public thresholds: Threshold[] = [];

    public loading: boolean = true;

    public trunksReceived: Subject<null> = new Subject();

    constructor() {
        super();

        this.sizes = [
            {
                id: 0,
                label: 'Small',
                cols: 5,
                rows: 3
            },
            {
                id: 1,
                label: 'Medium',
                cols: 5,
                rows: 6
            },
            {
                id: 2,
                label: 'Large',
                cols: 5,
                rows: 8
            }
        ];
        this.applySize(0);
    }

    applySettings(v: { [key: string]: any }) {
        super.applySettings(v);
        const trunkGroups = v.trunkGroups || [];
        this.configured = v.customer && v.location && v.equipment && trunkGroups.length > 0;
        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
        } else {
            this.customer = new Customer('', '');
        }
        this.equipmentId = v.equipment && v.equipment.value ? v.equipment.value : null;
        this.generatedNameTag = this.configured ? `${v.location.label} | ${v.equipment.label}` : 'Unconfigured';
        this.customNameTag = v.nameTag;

        // parse trunks array
        this.trunkGroups = trunkGroups.map((i: any) => +i.value);

        this.trunksReceived.next(null);
    }

    dispose() {}

    resetData() {
        this.ipoTrunks = [];
        this.acmTrunks = [];
        this.trunks = [];
    }

    public processThresholdData(data: Threshold[]): any {
        if (data && data.length > 0) {
            data.forEach((data: any) => {
                const threshold = new Threshold();
                threshold.commandTypeId = data['commandTypeId'];
                threshold.dapThresholdTemplateId = data['dapThresholdTemplateId'];
                threshold.alarmName = data['alarmName'];
                threshold.name = data['name'];
                threshold.customerId = data['customerId'];
                threshold.dAPMessageFullClassName = data['dapmessageFullClassName'];
                threshold.dapThresholdId = data['dapThresholdId'];
                threshold.description = data['description'];
                threshold.equipmentId = data['equipmentId'];
                threshold.jSONRules = data['jsonrules'];
                threshold.locationId = data['locationId'];
                const jsonObj = JSON.parse(data['jsonrules']);
                jsonObj.forEach((element: any) => {
                    if (element['field'] === 'groupNumber' || element['field'] === 'trunkNumber') {
                        threshold.fieldNumber = element['value'];
                    } else if (element['field'] === 'grpNumber') {
                        threshold.fieldNumber = element['value'];
                        threshold.field = threshold.fieldNumber.toString();
                    }
                });
                this.thresholds.push(threshold);
            });
            this.processThresholdsMapping();
        }
    }

    public processDataIpo(utilizationData: any[]) {
        if (utilizationData !== null) {
            // get utilization for each trunk
            this.acmTrunks = [];
            for (const table of utilizationData) {
                for (const row of table.data) {
                    const t = new TrunkGroup();
                    t.number = +row.TrunkNumber;
                    t.name = 'Group ' + t.number;
                    t.size = +row.NumberOfChannels;
                    t.active = +row.ChannelsInUse;
                    t.commandType = this.commandTypeIdIpoUtilization;
                    t.type = 'ipo';

                    if (this.trunkGroups.some(num => t.number === num)) {
                        this.acmTrunks.push(t);
                    }
                }
            }
            this.processTrunks();
        }
    }

    public processDataAep(utilizationData: any[]) {
        if (utilizationData !== null) {
            // get utilization for each trunk
            this.acmTrunks = [];
            for (const table of utilizationData) {
                for (const row of table.data) {
                    const t = new TrunkGroup();
                    t.number = +row.Channel;
                    t.name = 'Group ' + t.number;
                    t.size = +row.NumberOfChannels;
                    t.active = +row.ChannelsInUse;
                    t.commandType = this.commandTypeIdAepUtilization;
                    t.type = 'aep';

                    if (this.trunkGroups.some(num => t.number === num)) {
                        this.acmTrunks.push(t);
                    }
                }
            }
            this.processTrunks();
        }
    }

    public processDataAcm(groupsData: any[], utilizationData: any[]) {
        if (groupsData?.length && utilizationData?.length) {
            // create names map
            const nameMap: { [trunkGroupNumber: number]: string } = {};
            for (const table of groupsData) {
                for (const row of table.data) {
                    nameMap[row.GroupNumber] = row.GroupName;
                }
            }
            // get utilization for each trunk
            this.acmTrunks = [];
            for (const table of utilizationData) {
                for (const row of table.data) {
                    const t = new TrunkGroup();
                    t.number = +row.GroupNumber;
                    t.name = nameMap[t.number] || 'Unknown';
                    t.size = +row.GroupSize;

                    t.active = +row.ActiveMembers;
                    t.commandType = this.commandTypeIdAcmUtilization;
                    t.type = 'acm';

                    if (this.trunkGroups.some(num => t.number === num)) {
                        this.acmTrunks.push(t);
                    }
                }
            }
            this.processTrunks();
        }
    }

    private processTrunks() {
        this.loading = false;
        const trunks = (this.acmTrunks || []).concat(this.ipoTrunks || []);

        // colorise
        for (const t of trunks) {
            const delta = t.size - t.active;
            let severity: number;
            if (t.size <= 0) {
                severity = -1;
            } else if (delta < t.size * 0.1) {
                severity = 2;
            } else if (delta < t.size * 0.25) {
                severity = 1;
            } else {
                severity = 0;
            }
            t.severity = severity;
        }
        this.trunks = trunks;

        this.trunksReceived.next(null);

        this.processThresholdsMapping();
    }

    private processThresholdsMapping() {
        this.thresholds.forEach(threshold => {
            const trunk = this.trunks.find(
                trunk =>
                    trunk.number === threshold.fieldNumber &&
                    (threshold.commandTypeId === this.commandTypeIdAcmUtilization.toLowerCase() ||
                        threshold.commandTypeId === this.commandTypeIdAepUtilization.toLowerCase() ||
                        threshold.commandTypeId === this.commandTypeIdIpoUtilization.toLowerCase() ||
                        threshold.commandTypeId === this.commandTypeIdOutOfServiceThreshold.toLowerCase())
            );
            if (trunk) {
                trunk.thresholds.push(threshold);
            }
        });
    }
}
