import { SelectItem } from '@models/index';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeService {
  constructor(private http: HttpClient) { }

  getTimestamp(): string {
    return new Date().toISOString();
  }

  getTimeZones() {
    return this.http.get<any[]>('api/formng/timezones').subscribe(zones =>
      zones.map(tz => {
        const i = new SelectItem();
        i.label = tz.name;
        i.value = tz.id;
        return i;
      })
    );
  }
}
