<div class="flex-column gap-l">
    <ng-container *ngIf="!openSubcontainer">
        <!-- system -->
        <dashlet-section-processor-memory
            #processorMemory
            [showSystemChart]="dashlet.showSystemChart"
            [equipment]="dashlet.equipment"
            [customerId]="dashlet.customer.customerId"
            [locationId]="dashlet.location.locationId"
        >
        </dashlet-section-processor-memory>
        <!-- network connectivity -->
        <dashlet-section-network-connectivity
            [showNetworkGraph]="dashlet.showNetworkGraph"
            [equipment]="dashlet.equipment"
            [customerId]="dashlet.customer.customerId"
        >
        </dashlet-section-network-connectivity>
    </ng-container>
    <dashlet-section-network-interface
        *ngIf="(dashlet.showNetworkInterface && !openSubcontainer) || openSubcontainer === 'network-interface'"
        [equipment]="dashlet.equipment"
        [locationName]="dashlet.location.locationName"
        [customerName]="dashlet.customer.customerName"
        [commandTypeIdNetworkInterface]="dashlet.commandTypeIdNetworkInterface"
        (openDetails)="$event ? openDetails($event, 0) : closeDetails()"
    ></dashlet-section-network-interface>
    <ng-container *ngIf="!openSubcontainer">
        <!-- Application Status section -->
        <div *ngIf="dashlet.showApplicationStatus" class="section section-application-status">
            <dashlet-section-header>Application Status</dashlet-section-header>
            <div class="justify-space-between grow">
                <div
                    *ngFor="let row of dashlet.applicationStatusList"
                    class="flex-column gap-s mat-h2 metric"
                    [ngStyle]="{ 'border-left-color': getSeverityColor(getApplicationStatusColorIndex(row.status)) }"
                    matTooltip="Running since {{ row.stateStart }}"
                >
                    <div>{{ row.applicationName }}</div>
                    <div class="mat-h3">
                        {{ row.status }}
                    </div>
                </div>
            </div>
        </div>

        <!--Custom Scripts-->
        <div *ngIf="dashlet.showCustomScripts">
            <dashlet-section-custom-scripts [equipment]="dashlet.equipment" [customer]="dashlet.customer"> </dashlet-section-custom-scripts>
        </div>

        <!-- Service status -->
        <ng-container *ngIf="dashlet.showServiceStatus">
            <dashlet-section-services
                #services
                [equipment]="dashlet.equipment"
                [commandTypeIdServices]="[dashlet.commandTypeIdServiceStatus]"
                (openDetails)="openDetails('service', $event)"
            >
            </dashlet-section-services>
        </ng-container>

        <!-- SIP Protocol -->
        <div *ngIf="dashlet.showSIPProtocolStatus" class="section section-sip-protocol">
            <dashlet-section-header>SIP Protocol</dashlet-section-header>
            <dashlet-table [columns]="sipColumns" [dataSource]="dashlet.sipTableData" [tableHeightRows]="1" [sortable]="false"></dashlet-table>
        </div>

        <!-- SIP Calls -->
        <div *ngIf="dashlet.showSIPCallStatus" class="section section-sip-call">
            <dashlet-section-header>SIP Calls</dashlet-section-header>
            <div class="justify-space-around grow align-start" *ngIf="dashlet.sbcSipInfoList.length > 0; else noData">
                <div class="flex gap-m">
                    <div class="flex-column text-align-right equipment-detail-labels">
                        <div class="mat-body-2">Active Calls</div>
                        <div class="mat-body-2">Active SRTP Calls</div>
                        <div class="mat-body-2">TCP Registrations</div>
                        <div class="mat-body-2">UDP Registrations</div>
                        <div class="mat-body-2">TLS Registrations</div>
                    </div>
                    <div class="flex-column equipment-detail-values">
                        <div class="mat-body-1">{{ getSIPCallData().TotalActiveCalls ? getSIPCallData().TotalActiveCalls.total : '---' }}</div>

                        <div class="mat-body-1">{{ getSIPCallData().TotalActiveSRTPCalls ? getSIPCallData().TotalActiveSRTPCalls.total : '---' }}</div>

                        <div class="mat-body-1">
                            {{ getSIPCallData().TotalActiveTCPRegistrations ? getSIPCallData().TotalActiveTCPRegistrations.total : '---' }}
                        </div>

                        <div class="mat-body-1">
                            {{ getSIPCallData().TotalActiveUDPRegistrations ? getSIPCallData().TotalActiveUDPRegistrations.total : '---' }}
                        </div>
                        <div class="mat-body-1">
                            {{ getSIPCallData().TotalActiveTLSRegistrations ? getSIPCallData().TotalActiveTLSRegistrations.total : '---' }}
                        </div>
                    </div>
                </div>
                <div class="flex gap-m">
                    <div class="flex-column text-align-right equipment-detail-labels">
                        <div class="mat-body-2">Calls Received</div>

                        <div class="mat-body-2">Calls Allowed</div>

                        <div class="mat-body-2">Total Calls</div>
                    </div>
                    <div class="flex-column equipment-detail-values">
                        <div class="mat-body-1">{{ getSIPCallData().TotalCallsReceived ? getSIPCallData().TotalCallsReceived.total : '---' }}</div>

                        <div class="mat-body-1">{{ getSIPCallData().TotalCallsAllowed ? getSIPCallData().TotalCallsAllowed.total : '---' }}</div>

                        <div class="mat-body-1">{{ getSIPCallData().TotalCalls ? getSIPCallData().TotalCalls.total : '---' }}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Security Violations -->
        <div *ngIf="dashlet.showSecurityViolations" class="section section-services">
            <dashlet-section-header>Security Violations</dashlet-section-header>
            <!-- Bar -->
            <div class="flex-column gap-m">
                <dashlet-ratio-bar class="dashlet-cell" emptyTitle="No data available for Security Violations">
                    <dashlet-ratio-bar-item
                        [value]="getSecurityViolationsUp()"
                        [color]="getSeverityColor(0)"
                        [matTooltip]="getSecurityViolationsUp() + ' of ' + (getSecurityViolationsUp() + getSecurityViolationsDown()) + ' ok'"
                        (click)="openDetails('security', 0)"
                    ></dashlet-ratio-bar-item>
                    <dashlet-ratio-bar-item
                        [value]="getSecurityViolationsDown()"
                        [color]="getSeverityColor(2)"
                        [matTooltip]="getSecurityViolationsDown() + ' security violations'"
                        (click)="openDetails('security', 2)"
                    >
                    </dashlet-ratio-bar-item>
                </dashlet-ratio-bar>
                <div class="justify-space-between">
                    <h3 *ngIf="getSecurityViolationsUp() > 0" class="dashlet-link-underline" dashlet-link="true" (click)="openDetails('security', 0)">
                        <span class="mat-h2">{{ getSecurityViolationsUp() + ' ' }}</span>
                        <span class="mat-h3">Ok</span>
                    </h3>
                    <h3 *ngIf="getSecurityViolationsDown() > 0" class="dashlet-link-underline" dashlet-link="true" (click)="openDetails('security', 2)">
                        <span class="mat-h2">{{ getSecurityViolationsDown() + ' ' }}</span>
                        <span class="mat-h3">Alert</span>
                    </h3>
                </div>
            </div>
        </div>

        <ng-template #noData>
            <div class="dashlet-cell dashlet-label dashlet-label-n">No data available</div>
        </ng-template>
    </ng-container>
</div>

<!-- Security Violations Drilldown Content -->
<vsm-dashlet-subcontainer
    *ngIf="openSubcontainer === 'security'"
    [title]="dashlet.location.locationName + ' | ' + dashlet.equipment.equipmentName"
    [navPrevious]="'ASBC System Health - ' + dashlet.customer.customerName"
    [navCurrent]="'ASBC Security Violations - ' + (openSubcontainerSeverity === 0 ? 'OK' : 'alert')"
    (close)="closeDetails()"
>
    <ng-container subtitle>
        <span> {{ getSecurityViolationsDown() }} of {{ getSecurityViolationsUp() + getSecurityViolationsDown() }} violation alerts </span>
    </ng-container>
    <ng-container body>
        <dashlet-table [columns]="securityColumns" [sortable]="false" [tableHeightRows]="6" [dataSource]="dashlet.securityTableData"></dashlet-table>
    </ng-container>
</vsm-dashlet-subcontainer>

<!-- create thresholds alerts drilldown -->
<vsm-dashlet-subcontainer
    *ngIf="openSubcontainer === 'createThreshold' || openSubcontainer === 'editThreshold'"
    [navPrevious]="'SBC System Health - ' + dashlet.customer.customerName"
    [navCurrent]="(openSubcontainer === 'createThreshold' ? 'Create' : 'Edit') + ' Threshold'"
    (close)="closeDetails()"
>
    <ng-container title>{{ openSubcontainer === 'createThreshold' ? 'New' : 'Edit' }} {{ selectedField }} Threshold </ng-container>
    <ng-container body>
        <threshold-item #thresholdItem [settings]="dashlet.getSettings()" [type]="'SBC'" [threshold]="selectedThreshold"> </threshold-item>
    </ng-container>
    <div footer class="row row-buttons">
        <div>
            <button type="button" pButton class="btn button-details" (click)="closeDetails()" label="Cancel"></button>
        </div>
        <div *ngIf="openSubcontainer === 'editThreshold'; else addThreshold">
            <button type="submit" pButton class="btn button-details" (click)="saveEdit()" [disabled]="!thresholdItem.formValid()" label="Save"></button>
        </div>
        <ng-template #addThreshold>
            <button type="submit" pButton class="btn button-details" (click)="onSubmit()" [disabled]="!thresholdItem.formValid()" label="Save"></button>
        </ng-template>
    </div>
</vsm-dashlet-subcontainer>
